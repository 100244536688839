import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import PopSentenceTransformUpdateQuestion from './sentence-transform-q-update';
import '../../App.css';
import {
	Avatar, Button, Box, Card, Chip, chipClasses, CircularProgress, FormControl, Grid, IconButton, Input, InputLabel,
	Menu, MenuItem,
	Select, Stack, Switch, TextField, Typography, FormControlLabel, Checkbox,
	imageListClasses,
	Icon
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { AppHelper } from '../../AppHelper';
import SentenceTransformExplainer from "./sentence-transform-explainer";
import SentenceTransformExercise from "./sentence-transform-exercise";
import SentenceTransformExerciseView from "./sentence-transform-exercise-view";
import SentenceTransformQnA from "./sentence-transform-qna";
import SentenceTransformImages from "./sentence-transform-images";
import StarRoundedIcon from '@mui/icons-material/StarRounded';

export default function SentenceTransformView({exerciseId, onViewDone, questionSetId}) {
	const [oUser, setOUser] = useState(null);
	const [exercise, setExercise] = useState([]);
	const [incorrectQuestion, setIncorrectQuestion] = useState([]);
	const [correctQuestion, setCorrectQuestion] = useState([]);
	const [questionY, setQuestionY] = useState(0);
	const [exerciseCount, setExerciseCount] = useState(0);
	const [exerciseYCount, setExerciseYCount] = useState(0);
	// const [questionN, setQuestionN] = useState(0);
	const [questionTotal, setQuestionTotal] = useState(0);
	const [visibleExplanationIndex, setVisibleExplanationIndex] = useState(null);
	const [visibleExerciseIndex, setVisibleExerciseIndex] = useState(null);
	const [visibleExerciseViewIndex, setVisibleExerciseViewIndex] = useState(null);
	const [showIncorrect, setShowIncorrect] = useState(true);
	const [showCorrect, setShowCorrect] = useState(false);
	const [refreshList, setRefreshList] = React.useState(false);
	const [popQuestionOpen, setPopQuestionOpen] = useState(false);
	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const [currentIndexType, setCurrentIndexType] = useState(null);
	const [isAllCorrect, setIsAllCorrect] = useState(false);
	const [invalidImgCount, setInvalidImgCount] = useState(false);
	const [noImage, setNoImage] = useState(false);
	
	
	const questionEdit = async (row) => {
		setSelectedQuestion(row);		
		setPopQuestionOpen(true);
	  };

	  	 const fetchExerciseData = async (argUser) => {
			
			setIsAllCorrect(false);
			const _url = 'api/exercise/sentenceTransform/get';
			const _payload = {
				token: argUser.token, 
				profileId: argUser.profileId,
				exerciseId: exerciseId,
			}
			
			const _response = await AppHelper.apiPost(_url, _payload);
			// if(!_response || !_response.status){
			// 	console.log("Response ", _response);
			//   	return;
			// }else{
			let invalidCount = 0;
			if (_response.data && _response.data.pages) {
				const hasNoImage = _response.data.pages.some(page => page.pageImage === "");
				setNoImage(hasNoImage);
			}
			  
			if(_response.data && _response.data.pages){
				const hasNoImage = _response.data.pages.pageImage == ""; 
				if (hasNoImage) {
					setNoImage(true);
				}
			}	
			if(_response.data && _response.data.pages)
			{
				_response.data.pages.forEach(page => {
					if (page.status === 6) {
						invalidCount++;
					}
				})
			
				const questions = _response.data.pages.flatMap((page, iPage) => 
					page.questions.map(q => ({
						questionNo: q.questionNo,
						questionId: q.questionId,
						question: q.question,
						originalSentence: q.originalSentence, 
						status: q.status,
						generatedExercises: q.generatedExercises,
						pageIndex: iPage,
						exerciseY: q.generatedExercises.filter(x=>x.status == 1).length,
						correctedAnswer: q.correctedAnswer
					}))
				);

				setInvalidImgCount(invalidCount);
				setExercise(questions);
				setQuestionTotal(questions.length);
				setQuestionY(questions.filter(q => q.status === 1).length);
				
				var _exCount = 0;
				var _exYCount = 0;
				questions.forEach(q=>{
					_exCount += q.generatedExercises ? q.generatedExercises.length : 0;
					_exYCount += q.generatedExercises ? q.generatedExercises.filter(x=>x.status==1).length : 0;
				});
				setExerciseCount(_exCount);
				setExerciseYCount(_exYCount);
				// setQuestionN(questions.filter(q => q.status === 3).length);
			// }
				setIncorrectQuestion([...questions.filter(q => q.status == 3)]);
				setCorrectQuestion([...questions.filter(q => q.status == 1)]);
			}
		};
	
	const popQuestionClose = () => {
		setSelectedQuestion(null);
		setRefreshList(true);
		setPopQuestionOpen(false);
	  }

    useEffect(() => {
		if(!exerciseId) return;
		var _user = AppHelper.userCacheGet();
		if(!_user) return;
		setOUser(_user);

		console.log("questionSetId", questionSetId);
		setShowIncorrect(true);
		setShowCorrect(false);
		setVisibleExerciseIndex(null);
		setVisibleExplanationIndex(null);
		fetchExerciseData(_user);
		
		
	}, [exerciseId, refreshList]);

	useEffect(() => {
		setIsAllCorrect(questionY === questionTotal && questionY > 0);
		// console.log("Update isAllCorrect", questionY, questionTotal, isAllCorrect);
		
	}, [questionY, questionTotal]);

	const toggleExplanationVisibility = (index, arg) => {
		var _setVisible = visibleExplanationIndex !== index;
		setVisibleExplanationIndex(!_setVisible ? null : index);
		if(_setVisible){
			setVisibleExerciseIndex(null);
			setVisibleExerciseViewIndex(null);
		}
		if (arg == "I"){setCurrentIndexType("I");}
		if (arg == "C"){setCurrentIndexType("C");}
	};

	const toggleExerciseVisibility = (index, arg) => {
		var _setVisible = visibleExerciseIndex !== index;
		setVisibleExerciseIndex(!_setVisible ? null : index);
		if(_setVisible){
			setVisibleExplanationIndex(null);
			setVisibleExerciseViewIndex(null);
		}
		if (arg == "I"){setCurrentIndexType("I");}
		if (arg == "C"){setCurrentIndexType("C");}
	};
	const toggleExerciseViewVisibility = (index, arg) => {
		var _setVisible = visibleExerciseViewIndex !== index;
		setVisibleExerciseViewIndex(!_setVisible ? null : index);
		if(_setVisible){
			setVisibleExplanationIndex(null);
			setVisibleExerciseIndex(null);
		}
		if (arg == "I"){setCurrentIndexType("I");}
		if (arg == "C"){setCurrentIndexType("C");}
	};

	const toggleIncorrect = () => {
		setShowIncorrect(!showIncorrect);
	};
	
	const toggleCorrect = () => {
		setShowCorrect(!showCorrect);
	};

	const handleAnswerChange = (index, event) => {
		const newQuestions = exercise.questions.map((question, idx) => {
			if (idx === index){
				return { ...question, userResponse:event.target.value};
			}
			return question;
		})
		setExercise({ ...exercise, questions: newQuestions });
	}
	const exerciseSubmitDone = (arg, questionId)=> {
		if((exercise.length)<=visibleExerciseIndex){
			alert('Invalid Question Index!');
			return;
		}
				
		var qs = incorrectQuestion.find((x) => x.questionId == questionId);		
		if (qs) {
			qs.exerciseY += arg.filter((xx) => xx.status == 1).length;
			
			setIncorrectQuestion([...incorrectQuestion]);					
			fetchExerciseData(oUser);
		}
		else {
			var qs = correctQuestion.find((x) => x.questionId == questionId);		
			if (qs) {
				qs.exerciseY += arg.filter((xx) => xx.status == 1).length;
				
				setCorrectQuestion([...correctQuestion]);
				fetchExerciseData(oUser);
			}
		}
		
		var _questions = [...exercise];
		var _q = _questions[visibleExerciseIndex];
		_q.generatedExercises.push(...arg);
		
		// setIncorrectQuestion(_questions);
		setExerciseCount(exerciseCount + arg.length);
		setExerciseYCount(exerciseYCount + arg.filter(x=>x.status==1).length);
	}
	const onDelete = async()=>{
		if(!window.confirm('Are you sure you want to delete this Exercise?')) return;
		console.log("Deleted", exerciseId);
		var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/delete', {token: oUser?oUser.token:'NA', exerciseId: exerciseId});
		if(!_res || !_res.status){
			alert('ERROR: Failed to delete!');
			return;
		}
		else {
			if(onViewDone) onViewDone();
		}
	}


	return (
		<Box sx={{ flexGrow: 1, backgroundColor:'#fff' }}>
		<Grid container>
			<Grid item xs={12}>
				<Box sx={{fontWeight:'bold', fontFamily: "'Playpen Sans', cursive", fontSize:'2rem', textAlign:'center'}}>
					
				</Box>
				
				{!noImage && (
				  <Box>				  
					<SentenceTransformImages exerciseId={exerciseId} oUser={oUser} />
				  </Box>
				)}
				{invalidImgCount === 1 && 
				<Typography sx={{fontSize:'0.8rem', textAlign:'center'}}>
					1 page failed to transcribe.
				</Typography>}
				{invalidImgCount > 1 && 
				<Typography sx={{fontSize:'0.8rem', textAlign:'center'}}>
					{invalidImgCount} pages failed to transcribe.
				</Typography>}
				{/* <Typography style={{ fontSize: '20px', fontWeight: 'bold', padding: '2px 15px', marginBottom: '5px', marginTop: '10px'}}>
					{exercise.exerciseId}
				</Typography> */}
				<Grid container alignItems="center">
					<Grid item xs={6}>
						<Typography style={{ fontSize: '20px', fontWeight: 'bold', padding: '2px 15px', marginBottom: '5px', marginTop: '5px'}}>
							Your Score: {questionY}/{questionTotal}
						</Typography>
					</Grid>
						
					<Grid item xs={6} style={{textAlign:'right'}}>
						{exerciseCount>0 &&
						<Typography style={{ fontSize: '20px', fontWeight: 'bold', justifyContent: "right", alignContent: "right", padding: '2px 15px', marginBottom: '5px', marginTop: '5px'}}>
							Exercise Score: {exerciseCount==0?'-': `${exerciseYCount}/${exerciseCount}`}
						</Typography>
						}
					</Grid>
					<Grid item xs={6}>
					{isAllCorrect &&
					<Typography style={{ fontSize: '17px', fontWeight: 'normal', padding: '2px 15px', marginBottom: '5px', marginTop: '5px'}}>
					<StarRoundedIcon sx={{ mb: '-5px', mr:'5px', color: 'rgba(19, 130, 117, 0.8)' }}></StarRoundedIcon>
						Congratulations! You answered it all correctly!
					</Typography>
					}
					</Grid>
				</Grid>
				<Card variant="outlined" sx={{padding:1.5, marginRight:0, borderRadius:'20px', marginTop:'0.25rem', fontSize:'18px'}}>
					<Box display={'flex'}>
						<Typography textAlign="center" style={{ fontSize: '20px', fontWeight: 'normal', padding: '2px 15px', marginBottom: '5px'}}>
							INCORRECT 
						</Typography>
						{ incorrectQuestion &&
						<Chip onClick={toggleIncorrect}
							icon={showIncorrect ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />} label={incorrectQuestion.length} 
							className='app-bg-g'
							sx={{
							//backgroundColor:'rgb(99, 136, 137)',
							color: '#fff',
							fontWeight: 'bold',
							[`& .${chipClasses.icon}`]: {
								color: '#fff'
							},
							width:'65px'
							}} 
						/>
						}
					</Box>
					<Box>
					{showIncorrect && incorrectQuestion && (
						incorrectQuestion.map((item, index) => (
							<Grid container key={index} sx={{}}>
							{/* <Grid key={index} sx={{mb:2, p:2}} xs={8}> */}
								<Grid item xs={12} md={8}>
									<SentenceTransformQnA key={'qna_3_' + index} 
										questionNo={item.questionNo} 
										status={item.status}
										questionText={item.originalSentence} 
										answer={item.question} 
										pageIndex={item.pageIndex}
										correctedAnswer={item.correctedAnswer}
										onEdit={() => questionEdit(item)}>
									</SentenceTransformQnA>
									{/* <Typography>Question {item.questionNo}</Typography>
									<Typography>Q: {item.originalSentence}</Typography>
									<Typography>A: {parseAndUnderline(item.question)}</Typography> */}
								</Grid>
								<Grid item xs={12} md={4} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
									{/* {<Button onClick={() => questionEdit(item)} sx={{mb: 1, marginRight:'.2rem'}} variant="outlined">Update</Button>} */}
									<Button 
										onClick={() => toggleExplanationVisibility(index, "I")} 
										variant="contained"
										sx={{mb: 1, 
											marginRight:'.2rem', 
											backgroundColor: visibleExplanationIndex === index && currentIndexType === "I" ? "#00695c" : "rgba(19, 130, 117, 1)", 
											'&:hover': {
												backgroundColor: "rgba(19, 130, 117, 0.8)"
											}}} >
											Explanation
									</Button>
									<Button 
										onClick={() => toggleExerciseVisibility(index, "I")} 
										variant="contained"
										sx={{mb: 1, 
											marginRight:'.2rem', 
											backgroundColor: visibleExerciseIndex === index && currentIndexType === "I" ? "#00695c" : "rgba(19, 130, 117, 1)", 
											'&:hover': {
												backgroundColor: "rgba(19, 130, 117, 0.8)"
											}}} >
											Exercise
									</Button>
									{ item.generatedExercises.length>0 &&
										<Button 
											onClick={() => toggleExerciseViewVisibility(index, "I")} 
											variant="contained"
											sx={{mb: 1, 
												marginRight:'.2rem', 
												backgroundColor: visibleExerciseViewIndex === index && currentIndexType === "I" ? "#00695c" : "rgba(19, 130, 117, 1)", 
												'&:hover': {
													backgroundColor: "rgba(19, 130, 117, 0.8)"
												}}} >
												{item.exerciseY}/{item.generatedExercises.length}
										</Button>
									}
								</Grid>
								{visibleExplanationIndex === index && currentIndexType == "I" && (
									<Grid item xs={12}>
										<SentenceTransformExplainer question={item} oUser={oUser} />
									</Grid>
								)}
								{visibleExerciseIndex === index && currentIndexType == "I" && (
									<Grid item xs={12} pl={2}>
										<SentenceTransformExercise question={item} oUser={oUser} submitDone={exerciseSubmitDone}/>
									</Grid>
								)}
								{visibleExerciseViewIndex === index && currentIndexType == "I" && (
									<Grid item xs={12} pl={2}>
										<SentenceTransformExerciseView question={item} />
									</Grid>
								)}
							</Grid>
					)))}
					</Box>
				</Card>
				<Card variant="outlined" sx={{padding:1.5, marginRight:0, borderRadius:'20px', marginTop:'0.25rem', fontSize:'18px'}}>
					<Box display={'flex'}>
						<Typography textAlign="center" style={{ fontSize: '20px', fontWeight: 'normal', padding: '2px 15px', marginBottom: '5px'}}>
							CORRECT
						</Typography>
						{ correctQuestion && 
						<Chip onClick={toggleCorrect}
							icon={showCorrect ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />} label={correctQuestion.length} 
							className='app-bg-g'
							sx={{
							//backgroundColor:'rgb(99, 136, 137)',
							color: '#fff',
							fontWeight: 'bold',
							[`& .${chipClasses.icon}`]: {
								color: '#fff'
							},
							width:'65px'
							}} 
						/>
						}
					</Box>
					<Box sx={{ }}>
						{showCorrect && (
						  correctQuestion.map((item, index) => (
							<Grid container key={index} sx={{ mb: 2 }}>
							  {/* Question and Answer Component */}
							  <Grid item xs={12} md={8}>
							  <SentenceTransformQnA
									key={'qna_3_' + index}
									questionNo={item.questionNo}
									status={item.status}
									questionText={item.originalSentence}
									answer={item.question}
									pageIndex={item.pageIndex}
									onEdit={() => questionEdit(item)}>
								</SentenceTransformQnA>
							  </Grid>
							  <Grid item xs={12} md={4} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
									{/* <Button onClick={() => questionEdit(item)} sx={{mb: 1, marginRight:'.2rem'}} variant="outlined">Update</Button> */}
									<Button 
										onClick={() => toggleExplanationVisibility(index, "C")} 
										variant="contained"
										sx={{mb: 1, 
											marginRight:'.2rem', 
											backgroundColor: visibleExplanationIndex === index && currentIndexType === "C" ? "#00695c" : "rgba(19, 130, 117, 1)", 
											'&:hover': {
												backgroundColor: "rgba(19, 130, 117, 0.8)"
											}}} >
											Explanation
									</Button>
									<Button 
										onClick={() => toggleExerciseVisibility(index, "C")} 
										variant="contained"
										sx={{mb: 1, 
											marginRight:'.2rem', 
											backgroundColor: visibleExerciseIndex === index && currentIndexType === "C" ? "#00695c" : "rgba(19, 130, 117, 1)", 
											'&:hover': {
												backgroundColor: "rgba(19, 130, 117, 0.8)"
											}}} >
											Exercise
									</Button>
									{ item.generatedExercises.length>0 &&
										<Button 
											onClick={() => toggleExerciseViewVisibility(index, "C")} 
											variant="contained"
										sx={{mb: 1, 
											marginRight:'.2rem', 
											backgroundColor: visibleExerciseViewIndex === index && currentIndexType === "C" ? "#00695c" : "rgba(19, 130, 117, 1)", 
											'&:hover': {
												backgroundColor: "rgba(19, 130, 117, 0.8)"
											}}} >
												{item.exerciseY}/{item.generatedExercises.length}</Button>
									}
								</Grid>
								{visibleExplanationIndex === index &&  currentIndexType == "C" && (
									<Grid item xs={12}>
										<SentenceTransformExplainer question={item} oUser={oUser} />
									</Grid>
								)}
								{visibleExerciseIndex === index && currentIndexType == "C" && (
									<Grid item xs={12} pl={2}>
										<SentenceTransformExercise question={item} oUser={oUser} submitDone={exerciseSubmitDone}/>
									</Grid>
								)}
								{visibleExerciseViewIndex === index && currentIndexType == "C" && (
									<Grid item xs={12} pl={2}>
										<SentenceTransformExerciseView question={item} />
									</Grid>
								)}
							  
							
							  <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								{/* {<Button
								  onClick={() => questionEdit(item)}
								  sx={{ mb: 1, marginRight: '.2rem' }}
								  variant="outlined"
								>
								  Update
								</Button> } */}
								<Button
									sx={{ mb: 1, marginRight: '.2rem', visibility: 'hidden' }}
									variant="outlined"
								  >
									Explanation
								  </Button>
								
								  <Button
									sx={{ mb: 1, marginRight: '.2rem', visibility: 'hidden' }}
									variant="outlined"
								  >
									Exercise
								  </Button>
							  </Grid>
							</Grid>
						  ))
						)}

					</Box>
				</Card>
		 	</Grid>
			<Grid item xs={12} mt={1}>
				<Button onClick={onDelete} color="error" variant="outlined" style={{marginRight:'2px'}}>DELETE</Button>
			</Grid>
 		</Grid>
		 <PopSentenceTransformUpdateQuestion
			model={selectedQuestion}
			open={popQuestionOpen}
			onClose={popQuestionClose}
		  />
		</Box>
		
    );
}

// return(
// 	<Box sx={{ flexGrow: 1, backgroundColor:'#fff' }}>
// 		<Grid container>
// 			<Grid item xs={12}>
// 				<Box sx={{fontWeight:'bold', fontFamily: "'Playpen Sans', cursive", fontSize:'2rem', textAlign:'center'}}>
// 					Sentence Transformation
// 				</Box>
// 				<Typography style={{ fontSize: '20px', fontWeight: 'bold', padding: '2px 15px', marginBottom: '5px', marginTop: '10px'}}>
// 					{exercise.exerciseId}
// 				</Typography>
// 				<Typography style={{ fontSize: '18px', fontWeight: 'bold', padding: '2px 15px', marginBottom: '5px', marginTop: '5px'}}>
// 					{/* Your Score: {exercise.score} */}
// 				</Typography>
// 			</Grid>
// 			<Box style={{
// 				alignItems:'center', 
// 				padding:'0 15px', 
// 				height:'100%', 
// 				fontFamily:"'Arial', sans-serif", 
// 				fontSize:'large', 
// 				marginBottom:'20px', 
// 			}}>
// 				<Typography>Incorrect</Typography>
// 				{exercise.questions.map((question, index) => (
// 					<Grid container item xs={12} key={index} sx={{p:2}} alignItems="center">
// 					<Grid item xs>
// 						<Typography>Q: {question.question}</Typography>
// 						<Typography>A: {question.userResponse}</Typography>
// 					</Grid>
// 					<Grid item container sx={{ml:'auto'}}>
// 						<Button onClick={()=>toggleExplanationVisibility(index)}>
// 							Explaination
// 						</Button>
// 						<Button onClick={()=>toggleExerciseVisibility(index)}>
// 							Exercise
// 						</Button>
// 					</Grid>
// 					{question.visibleExplanation && (
// 						<Grid iton xs={12}>
// 							<img src="image.png" alt="Explaination" style={{width:'100%'}}/>
// 						</Grid>
// 					)}<Grid item xs={12}>
// 					</Grid>
// 					{question.visibleExercise && (
// 						<Grid item xs={12}>
// 							<TextField 
// 							fullWidth 
// 							variant="standard" 
// 							placeholders="Type your answer here"
// 							value={question.userResponse}
// 							onChange={(e) => handleAnswerChange(index, e.target.value)}
// 							/>
// 						</Grid>
// 					)}
// 					</Grid>
					
// 				))}	
// 			</Box>
// 		</Grid>
// 	</Box>
// )
// }
