import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppHelper } from "../../AppHelper";
import EssaySubmitStatus from "../essay/essay-submit-status"; 
import PageHomeEssay from '../home-essay';

export default function PopResubmit({open, onClose, onResubmitPosted, recEssay, essayId}) {
  //const { onClose, open, onSubmit, essayId } = props;
  const [loading, setLoading] = useState(false);
  const [popOpen, setPopOpen] = useState(false);
  const [newEssayId, setNewEssayId] = useState("");
  const [startReprocess, setStartReprocess] = useState(false);

  
  useEffect(() => {
    setPopOpen(open);
  }, [open]);
  useEffect(() => {
  }, [recEssay]);

  const handleResubmit = (arg) => {
    const confirm = window.confirm("Are you sure you want to resubmit?");
    if (!confirm) {
      return;
    }
    resubmitEssay();
  }

  const resubmitEssay = async(arg) => {
    var _user = AppHelper.userCacheGet();
    var _url = 'api/essay/resubmitToReplace';
    var _payload = {token: _user.token, 
                    essayId: essayId,
                    replace: true
    };
    var _res = await AppHelper.apiPost(_url, _payload);

    if (!_res || !_res.status){
      console.error("Error: Failed to resubmit essay. ")
      return;
    }
    setNewEssayId(_res.data.essayId);
    setStartReprocess(true);
  }

  const handleEssayStatusCheckDone = ({ code, essayId }) => {
    if (code === 'NEW') {
      if (onResubmitPosted) {
        onResubmitPosted(essayId);
      }
      setPopOpen(false);
      if (onClose) {
        onClose();
      }
    }
  };

  const onResubmit = async(arg)=>{
    var _user = AppHelper.userCacheGet();
    var _url = 'api/essay/create/withVersion/async';
    console.log(_user, recEssay);
    var _payload = {token: _user.token, imgs:[], 
      essayId: recEssay.essayId,
      text: recEssay.text, 
      topic: recEssay.topic, grade: recEssay.grade, addScore:0, profileId:_user.profileId,
    };
    _payload.verScore = 'v' + recEssay.version;
    _payload.verCorr = 'v' + '2.0';//versionCorr;
    console.log('api call', _url, _payload);
    var _res = await AppHelper.apiPost(_url, _payload);
    console.log('resubmitted', _res);
    if(!_res.status){
      alert('ERROR');
      return;
    }
    var _recEssay = {...recEssay};
    _recEssay.essayId = _res.data.essayId;
    if(onResubmitPosted) onResubmitPosted(_res.data.essayId);
    // setRecEssay(_recEssay);
    // setTimeout(() => {
    //   setStartCheckStatus(true);
    // }, 1000*1);
  }
  const handleClose = () => {
    if(onClose) onClose();
  };

  return (
    <Dialog onClose={handleClose} fullWidth maxWidth="xs" open={popOpen}>
		  <DialogTitle>Resubmit</DialogTitle>
      { !startReprocess &&
      <div>
		  <DialogContent>
        {/* <Alert severity="warning">Oops! It looks like there was an issue processing your previous submission. <br/><span style={{fontWeight:'bold'}}>Please try again.</span>
        
        </Alert> */}
        <Box style={{textAlign:'center'}} mt={3}>
          <Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} 
                  onClick={()=>handleResubmit()} 
                  color="info" 
                  variant="contained">
                    Resubmit
          </Button>
        </Box>
        {/* <Box style={{textAlign:'center', fontSize:'smaller'}} mt={5}>
          If the problem persists, feel free to reach out to our support team at <a href="mailto:support@essayace.ai">support@essayace.ai</a> for assistance.
        <br/><br/>Thank you for your patience!
        </Box> */}
		  </DialogContent>
		  <Button onClick={handleClose} style={{ position: 'absolute', top: 0, right: 0 }}>
        <CloseIcon />
      </Button>
      </div>
      }
      { startReprocess &&
    <Dialog onClose={handleClose} fullWidth maxWidth="s" open={popOpen}>
          <EssaySubmitStatus essayId={newEssayId} essayStatusCheckDone={handleEssayStatusCheckDone} />
          </Dialog>
      }
	  </Dialog>
  );
}