import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import CssBaseline from "@mui/material/CssBaseline";
import EditIcon from '@mui/icons-material/Edit';
import { alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import AppTopBar2 from '../common/app-top-bar2';
import DescriptionIcon from '@mui/icons-material/Description';
import AppLoading from '../common/app-loading';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import {
  Avatar, AppBar, CircularProgress, Button, Box, Card, CardActions, CardContent, Chip, Fab, Container, Divider, Drawer,
  List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Select, Toolbar, Typography, MenuItem, FormControl, InputLabel, TextField, Grid,
  Table, TableContainer, TableCell, TableHead, TableBody, TableRow, TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LinearProgress from '@mui/material/LinearProgress';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PhoneNumber from 'libphonenumber-js';
import { AppHelper } from '../../AppHelper';
import SpaCheck from '../common/spa-check';
import PageQuestion from '../sentence-transformation/question-set';
const dayjs = require('dayjs');

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary" fontSize={"0.7rem"}
          sx={{
            color: props.recSelected == 1 ? '#fff' : 'text.secondary'
          }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function ImageDialog({ open, imageUrls, onClose, topic }) {
//   return (
//     <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
//       <DialogTitle>View Question</DialogTitle>
//       <DialogContent>
//         <Grid container direction="column" spacing={3}>

//           <Grid item xs={12}>
//             <div dangerouslySetInnerHTML={{ __html: topic }} />
//           </Grid>

//           {imageUrls ? (
//             imageUrls.map((imageUrl, index) => (
//               <Grid item key={index} xs={6} sm={4} md={3}>
//                 <img
//                   src={`data:image/jpeg;base64,${imageUrl}`}
//                   alt={`Image ${index + 1}`}
//                   style={{ maxWidth: '100%' }}
//                 />
//               </Grid>
//             ))
//           ) : (
//             <CircularProgress />
//           )}
//         </Grid>
//       </DialogContent>
//       <Button onClick={onClose} style={{ position: 'absolute', top: 0, right: 0 }}>
//         <CloseIcon />
//       </Button>
//     </Dialog>

//   );
// }

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCellsEssay = [
  { id: 'createdOn', numeric: false, disablePadding: false, label: 'TIMESTAMP' },
  { id: 'uploadType', numeric: false, disablePadding: false, label: 'OWNERSHIP' },
  { id: 'title', numeric: false, disablePadding: false, label: 'TITLE' },
  // { id: 'topic', numeric: false, disablePadding: false, label: 'TOPIC' },
  { id: 'grade', numeric: false, disablePadding: false, label: 'GRADE' },
  // { id: 'images', numeric: false, disablePadding: false, label: 'HAS IMAGE' },
  { id: 'createdByName', numeric: false, disablePadding: false, label: 'CREATED BY' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'ACTIONS' }

];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = headCellsEssay;


  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function QuestionSetListTable({
  filterLog, popQuestionOpenParam, onClose, mode
}) {
  // const theme = createTheme({
  //   typography: {
  //     fontFamily: 'Noto Sans',
  //   }
  // });

  const today = new Date();
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const isPhoneVerifed = oUser && oUser.phoneNumber && oUser.phoneNumber.length > 0;
  const [popQuestionOpen, setPopQuestionOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [logList, setLogList] = useState([]);



  const onAddQuestion = () => {
    setSelectedRow(null);
    setSelectedRowData(null);
    setPopQuestionOpen(true);
  }

  const popQuestionClose = (refresh) => {	
    setSelectedRow(null);	
    setRefreshList(refresh === true);
    setPopQuestionOpen(false);
	if (onClose)
	{
		onClose(false);
	}
  }

  useEffect(() => {
    AppHelper.apiAuditPost("P-QS", "");
    var _user = AppHelper.userCacheGet();
    if (_user) {
      fetchIAM(_user);
	  
    }
  }, []);

  // useEffect(() => {
  //     fetchRowData(selectedRow);
  // }, []);


  const otpRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [viewMode, setViewMode] = React.useState('LIST');
  const [refreshList, setRefreshList] = React.useState(true);
  const [recEssay, setRecEssay] = React.useState({});
  const [autoSelectId, setAutoSelectId] = React.useState('');
  const [countEssay, setCountLog] = React.useState(0);
  const [countUser, setCountUser] = React.useState(0);

  const [tblOrderLog, setTblOrderLog] = React.useState('desc');
  const [tblOrderByLog, setTblOrderByEssay] = React.useState('tCode');
  //const [filterLog, setFilterLog] = React.useState('');

  const [tblOrderAcc, setTblOrderAcc] = React.useState('desc');
  const [tblOrderByAcc, setTblOrderByAcc] = React.useState('essayCount');
  const [filterAcc, setFilterAcc] = React.useState('');
  const [isSa, setIsSa] = useState(false);

  const fetchIAM = async (oUser) => {
    var _res = await AppHelper.apiPost('api/account/iam', { token: oUser.token });
    if (!_res || !_res.status || !_res.data || !_res.data.isSa) {
      return;
    }
    setIsSa(true);
  }

  const fetchRowData = async (row) => {
    var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/questionSet/get', { token: oUser.token, questionSetId: row.questionSetId});
    if (!_res  || !_res.data ) {
      return;
    }
    const dataWithGrade = {
      ..._res.data, 
      questionSetName: row.questionSetName,
      questionSetId: row.questionSetId,
      uploadType: row.uploadType, 
      saQuestion: row.saQuestion, 
      grade: row.grade
    }
    setSelectedRowData(dataWithGrade);
    console.log("Looook here", dataWithGrade);
    return _res.data;
  }


  useEffect(() => {
    if (!oUser) {
      navigate('/login');
      return;
    }
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleViewIconClick = async (row) => {
    setSelectedRow(row);
    fetchRowData(row);
    var _res = await AppHelper.apiPost('api/question/detail', { token: oUser.token, questionId: row.questionId });
    if (!_res || !_res.status) {
      setImageUrls([]);
      alert("No image for this question");
      return;
    }
    else {

      console.log(_res.question.imagesBase64.length)
      setImageUrls(_res.question.imagesBase64);
      setIsPopupOpen(true);
    }
  };

  const handleViewTitleClick = async (row) => {
    fetchRowData(row);
    var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/questionSet/get', { token: oUser.token, questionSetId: row.questionSetId });
    if (!_res || !_res.status) {
      setImageUrls([]);
      alert("No topic for this question");
      return;
    }
    else {
      // console.log(_res.question.imagesBase64.length)
      setSelectedTitle(_res.question.topic);
      setImageUrls(_res.question.imagesBase64);
      setIsPopupOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setSelectedTitle("");
    setIsPopupOpen(false);
    setImageUrls([]);
  };

  const questionEdit = async (row) => {
    setSelectedRow(row);
    const _res = await fetchRowData(row);
    if (!_res){
      console.error("Failed to fetch data for editing: ", _res);
    }
    setPopQuestionOpen(true);
  };
  const fetchLogList = async () => {
	var _res = await AppHelper.apiPost('api/account/iam', { token: oUser.token });
    logList.splice(0);
    setIsLoading(true);
    
    if (_res && _res.status && _res.data) {
      var url = 'api/exercise/sentenceTransform/questionSet/list';
    }

  //   if (_res && _res.status && _res.data && _res.data.isSa) {
  //     url = 'api/question/list/all';
  //   }
	// else{
	//   url = 'api/question/list';
	// }

    var _res = await AppHelper.apiPost(url, { token: oUser.token });
    console.log('_res', _res);
    if (!_res || !_res.status) {
      setIsLoading(false);
      return;
    }
    var _list = [...AppHelper.sortList(_res.questionList, 'createdOn', 2)];
	_list.map((row)=>{
		row.ownershipText = row.saQuestion == 1 ? "SYSTEM" : row.uploadType == 0 ? "SELF" : "TUITION CENTER "
	});
    setLogList(_list);
    setTimeout(() => { console.log('logList', _list); }, 1000);
    setIsLoading(false);
    setCountLog(_list.length);
    setRefreshList(false);
  };

  useEffect(() => {
    if(refreshList)
	{	
		fetchLogList();
	}

  }, [refreshList]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const questionDelete = async (arg) => {
    if (!window.confirm("Are you sure you want to delete the Question Set?")) return;
    setIsLoading(true);
    var _res = await AppHelper.apiPost("api/exercise/sentenceTransform/questionSet/delete", {
      token: oUser.token, 
      questionSetId: arg.questionSetId,
    });
    if (!_res || !_res.status) {
      alert("ERROR: Failed to delete!");
      setIsLoading(false);
      return;
    }
    setRefreshList(true);
  };

  const drawerItemColrs = {
    '1x': 'rgba(0,0,0,0.25)',
    '1xx': 'rgb(157, 188, 152)',
    '1': 'rgb(99, 136, 137)',
    '0x': 'white',
    '0': 'rgb(235, 217, 180)',
  };
  const drawerItemColorsText = {
    '1': 'rgba(255,255,255,1)',
    '0': 'rgba(0,0,0,0.6)',
  };
  const drawer = (
    <>
      <div style={{ overflowY: "auto", marginBottom: '110px' }}>
        <Box width={'100%'}>
          <List sx={{ fontFamily: 'Noto Sans' }}>

            {logList.map((rec, index) => (
              <React.Fragment key={rec.logId}>
                <ListItem
                  sx={{
                    backgroundColor: rec.selected ? drawerItemColrs[rec.selected] : drawerItemColrs['0'],
                    color: rec.selected ? drawerItemColorsText[rec.selected] : drawerItemColorsText['0'],
                  }}
                  disablePadding>
                  <ListItemButton >
                    <div style={{ widht: '100%', flex: 1 }}>
                      <Box>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {rec.viewTitle}
                        </Typography>
                      </Box>
                      <div>
                        <LinearProgressWithLabel value={rec.avgScoreP} recSelected={rec.selected} />
                      </div>
                      <Typography
                        sx={{
                          display: 'inline',
                          color: rec.selected ? drawerItemColorsText[rec.selected] : drawerItemColorsText['0'],
                          opacity: '0.8'
                        }}
                        component="span" variant="body2"
                      >
                        {rec.tTitle}
                      </Typography>
                    </div>
                  </ListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 50, right: 0, left: 0,
          height: '60px',
          borderTop: '0 solid rgba(0,0,0,0.12)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {/* <Button sx={{
        backgroundColor:'rgb(99, 136, 137)', 
        ":hover":{backgroundColor:'rgb(157, 188, 152)'},
        width:'100%',
        margin:'0 5px 22px 5px'
        }}
        onClick={essayAddClick} color="info" variant="contained" size="large">
        + ADD
      </Button> */}
      </div>
    </>
  );
  const mobileOpenClick = (arg) => {
    setMobileOpen(!mobileOpen);
  };
  const back2Home = () => {
    navigate('/');
  };
  const listSortAndFilterEssay = () => {
    var _list = [...logList];
    if (filterLog && filterLog.length > 0) {
      var _filterText = filterLog.toLowerCase();
      _list = _list.filter(r =>
       (r.title && r.title.toLowerCase().includes(_filterText))
	   || (r.questionSetName && r.questionSetName.toLowerCase().includes(_filterText))
	   || (r.ownershipText && r.ownershipText.toLowerCase().includes(_filterText))
        || (r.grade && r.grade.toLowerCase().includes(_filterText))
        || (r.createdByName && r.createdByName.toLowerCase().includes(_filterText))

      );
    }
    // No need this part of code here, for now. 
    // _list.forEach(r => {
    //   r.topicView = r.topic;
    //   const words = r.topicView.split(' ');
    //   if (words.length > 10) {
    //     r.topicView = words.slice(0, 10).join(' ') + '...';
    //   }
    // });
    return stableSort(_list, getComparator(tblOrderLog, tblOrderByLog));
  };
  
  const backToOverview = () => {
    //setViewMode('LIST-QUESTION');
    navigate('/sa');
  }
  const visibleLogs = React.useMemo(
    () =>
      listSortAndFilterEssay()
    //stableSort(logList, getComparator(tblOrderLog, tblOrderByLog))
    // .slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // )
    ,
    //[order, orderBy, page, rowsPerPage],
	
    [logList, tblOrderLog, tblOrderByLog, filterLog]
  );
  const tblSortLog = (event, property) => {
    const isAsc = tblOrderByLog === property && tblOrderLog === 'asc';
    setTblOrderLog(isAsc ? 'desc' : 'asc');
    setTblOrderByEssay(property);
  };
  
  useEffect(() => {    
  setSelectedRowData(null);
	setPopQuestionOpen(popQuestionOpenParam);
  }, [popQuestionOpenParam]);


  const container = window !== undefined ? () => window.document.body : undefined;
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppTopBar2 mobileOpen={mobileOpen} mobileOpenClick={mobileOpenClick} />
        {isLoading ? (
          <AppLoading />
        ) : (
          <>
            {/* <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0, md:0 }, border:'0 solid red' }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'block', md:'none' },
            minWidth: '300px'
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            backgroundColor:'red',
            minWidth:'300px'
          }}
          PaperProps={{
            sx: {
              backgroundColor: "#f9efdb", //color: "red",
              marginTop:'65px',
              width: drawerWidth
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box> */}
            <Box
              component="main"
              sx={{
                flexGrow: 1, p: 3,
                //width: { md: `calc(100% - ${drawerWidth}px)` },
                width: '100%',
                marginTop: '50px'
              }}
            >

              {viewMode == 'LIST' &&
                <>
                 
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      {/* <TableHead>
                  <TableRow>
                    <TableCell align="center">GRADE</TableCell>
                    <TableCell align="left">TITLE</TableCell>
                    <TableCell align="center">SCORE</TableCell>
                    <TableCell align="center">SUBMITTED ON</TableCell>
                    <TableCell align="left">SUBMITTED BY</TableCell>
                  </TableRow>
                </TableHead> */}
                      <EnhancedTableHead
                        //numSelected={selected.length}
                        order={tblOrderLog}
                        orderBy={tblOrderByLog}
                        //onSelectAllClick={handleSelectAllClick}
                        onRequestSort={tblSortLog}
                        rowCount={logList.length}
                        gid='G1'
                      />
                      <TableBody>
                        {visibleLogs.map((row) => (
                          <TableRow
                            key={row.logId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="left">{new Date(row.createdOn).toLocaleString()}</TableCell>
                            <TableCell align="left">{row.saQuestion == 1 ? "SYSTEM" : row.uploadType == 0 ? "SELF" : "TUITION CENTER "}</TableCell>
                            <TableCell align="left">{row.questionSetName}</TableCell>
                            {/* <TableCell align="left">
                              <div dangerouslySetInnerHTML={{ __html: row.topicView }} />
                            </TableCell> */}
                            <TableCell align="left">{row.grade}</TableCell>
                            {/* <TableCell align="left">{row.images ? "Y" : "N"}</TableCell> */}
                            <TableCell align="left">{row.createdByName}</TableCell>
                            <TableCell>
							  {!(!isSa && row.saQuestion === 1) && (
								<IconButton
								  onClick={() => questionEdit(row)}
								  aria-label="edit"
								>
								  <EditIcon />
								</IconButton>
							  )}
							  {/* <IconButton onClick={() => handleViewTitleClick(row)}>
								<DescriptionIcon />
							  </IconButton> */}
							  {!(!isSa && row.saQuestion === 1) && (
								<IconButton
								  onClick={() => questionDelete(row)}
								  aria-label="delete"
								>
								  <DeleteForeverIcon />
								</IconButton>
							  )}
							</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              }
            </Box>
            {/* <ImageDialog open={isPopupOpen} topic={selectedTitle} imageUrls={imageUrls} onClose={handleCloseDialog} /> */}
          </>
        )}
        <SpaCheck></SpaCheck>
      </Box>
      <PageQuestion
        model={selectedRowData}
        open={popQuestionOpen}
        onClose={popQuestionClose}
        mode = {mode}
      />
    </>
  );

}