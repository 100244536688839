import React, {useState, useEffect, useRef} from "react";
import { CircularProgress, Snackbar } from '@mui/material';
import { EssayHelper } from "../helpers/essay.helper";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Avatar, Box, Button, Container, FormControl, Grid, IconButton, InputLabel, Paper, 
  List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, 
  Divider, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { AppHelper } from '../AppHelper';
import AppTopBar from './common/app-top-bar';
import { useEssayContext } from "../contexts/context-essay";

export default function PageProfile() {
  const navigate = useNavigate();
  const [oUser, setOUser] = useState(AppHelper.userCacheGet());
  const [appVer, setAppVer] = useState('-');
  const [gradeOK, setGradeOK] = useState(true);
  const [grade, setGrade] = useState((oUser && oUser.grade) ? oUser.grade : 'P5');
  const [profiles, setProfiles] = useState([]);
  const [isCreatingProfile, setIsCreatingProfile] = useState(false);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [newProfileName, setNewProfileName] = useState('');
  const [newEmail1, setNewEmail1] = useState('');
  const [newEmail2, setNewEmail2] = useState('');
  
  const [gradeList, setGradeList] = useState([]);
  const [newProfileGrade, setNewProfileGrade] = useState('');
  const [updatedProfileId, setUpdatedProfileId] = useState('');
  const [loading, setLoading] = useState(false);  
  const [isChangingPicture, setIsChangingPicture] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDefaultProfileUpdated, setIsDefaultProfileUpdated] = useState(false);
  const refTopBar = useRef(null);
  const [accountType, setAccountType] = useState(oUser.accountType || 'STU');
  const [email1, setEmail1] = useState(oUser.email1 || '');
  const [email2, setEmail2] = useState(oUser.email2 || '');
  const [isUpdatingAccountType, setIsUpdatingAccountType] = useState(false);
  const {essayList, isEssayListLoaded, setIsEssayListLoaded, loadEssayList, essaySelect} = useEssayContext();
  const [openSnackBar, setOpenSnackBar] = useState(false);

  useEffect(() => {
    AppHelper.apiAuditPost("P-P", "");
    //console.log('profile-isEssayListLoaded',isEssayListLoaded, essayList);
    //setIsEssayListLoaded(false);
  }, []); 
  
  const openChangePictureDialog = (profile, isDefault = false) => {
	  setUpdatedProfileId(profile.profileId);
	  setIsDefaultProfileUpdated(isDefault);
	  setIsChangingPicture(true);
	};

	const closeChangePictureDialog = () => {
	  setUpdatedProfileId('');
	  setIsChangingPicture(false);
	};
		
	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const submitProfilePicture = async () => {
	  if (!selectedFile) {
		alert('Please select a file');
		return;
	  }

	  const reader = new FileReader();
	  reader.onloadend = async () => {
		const base64String = reader.result.split(",")[1];
		try {
		  const response = await AppHelper.apiPost('api/profile/photo/update', {	
			  profileId:updatedProfileId,
			  token: oUser.token,
			  imgBase64: base64String
		
		  });
		  
		  if (response.status) {
			alert('Profile picture updated successfully');
			setIsChangingPicture(false)
			fetchProfiles();
			
			if (isDefaultProfileUpdated)
			{
				oUser.picture = base64String;
				AppHelper.userCacheUpdate(oUser);
			}
			// Update the profile picture in the UI if needed
		  } else {
			alert('Failed to update profile picture: ' + response.message);
		  }
		} catch (error) {
		  console.error('Error updating profile picture:', error);
		  alert('Failed to update profile picture');
		}
	  };
	  reader.readAsDataURL(selectedFile);
	};

  const gradeList0 = [
    // { v: 'P1', n: 'PRIMARY 1'},
    // { v: 'P2', n: 'PRIMARY 2'},
    // { v: 'P3', n: 'PRIMARY 3'},
    { v: 'P4', n: 'PRIMARY 4'},
    { v: 'P5', n: 'PRIMARY 5'},
    { v: 'P6', n: 'PRIMARY 6'}
  ];
  
  const accountTypeList = [
    { v: 'STU', n: 'STUDENT' },
    { v: 'TEA', n: 'TUTOR' },
    { v: 'PAR', n: 'PARENT' },
	  { v: 'TUI', n: 'TUITION CENTER' },
  ];
  
  const openEditDialog = (profile) => {
	  console.log("profiles", profiles);
	  console.log("profile", profile);
	  setNewProfileName(profile.name);
	  setNewProfileGrade(profile.grade);
	  setUpdatedProfileId(profile.profileId);
	  setNewEmail1(profile.email1);
	  setNewEmail2(profile.email2);
	  setIsUpdatingProfile(true);
	};
  
  const fetchProfiles = async () => {
    try {
      setLoading(true);
      const response = await AppHelper.apiPost('api/profile/list', {token: oUser.token});
      if (response && response.data) {
		let profiles = response.data;
		profiles.forEach((x) => (x.isOwnProfile = 1));

		const responseShare = await AppHelper.apiPost('api/profile/share/list', { token: oUser.token });

		if (responseShare && responseShare.data) {
			const profileShare = responseShare.data;
			profileShare.forEach((x) => (x.isOwnProfile = 0));
			
			profiles = [...profiles, ...profileShare];
		}

		setProfiles(profiles);
	} else {
        console.error('Failed to fetch profiles:', response);
      }
    } catch (error) {
      console.error('Error fetching profiles:', error);
    } finally {
		  setLoading(false); 
		}
  };
  
  const fetchGrades = async () => {
	 var grade = await EssayHelper.essayGradeGet(oUser.token);
	 setGradeList(grade);
  };
  
  useEffect(() => {    
    fetchProfiles();
	fetchGrades();
  }, []);
  
  const openCreateProfileDialog = () => {
    setIsCreatingProfile(true);
    setIsUpdatingProfile(false);
    setNewProfileName('');
	setNewEmail1('');
	setNewEmail2('');
    setNewProfileGrade('');
  };

  const closeCreateProfileDialog = () => {
    setIsCreatingProfile(false);
	  setIsUpdatingProfile(false);
  };
  
  const closeAccountTypeDialog = () => {    
	  setIsUpdatingAccountType(false);
  };

  const createProfile = async () => {
    if (!newProfileName || !newProfileGrade) {
      alert('Please fill in all fields');
      return;
    }
    const _res = await AppHelper.apiPost('api/profile/add', {
      grade: newProfileGrade,
      name: newProfileName,	  
	  email: oUser.email,
      token: oUser.token,
	  email1:newEmail1,
	  email2:newEmail2,
    });
    if (_res.status) {
      alert('Profile created successfully');
      setNewProfileName('');
      setNewProfileGrade('');
	  setNewEmail1('');
	  setNewEmail2('');
      closeCreateProfileDialog();
	    fetchProfiles();
    } else {
      alert('Failed to create profile: ' + _res.message);
    }
  };
  
  const handleSwitchProfile = (name, selectedProfileId) => {
    const confirmed = window.confirm("Are you sure you want to switch to profile " + name + "?");
    if (confirmed) {
      AppHelper.profileDefaultCacheSet({selectedProfileId});
      setOpenSnackBar(true);
      // alert('Profile switched successfully');
    
		var _profile = profiles.find(p=>p.profileId == selectedProfileId);
		if(!_profile){
		  alert('Profile data not found!');
		  return;
		}
		var _oUserSwitch = {...oUser};
		_oUserSwitch.name = _profile.name;
		_oUserSwitch.profileId = _profile.profileId;
		_oUserSwitch.grade = _profile.grade;
		_oUserSwitch.picture = _profile.photo;
		AppHelper.userCacheSet(_oUserSwitch);
		//setRefreshList(!refreshList);
    loadEssayList(_oUserSwitch);
		setOUser(_oUserSwitch);
    setGrade(_oUserSwitch.grade);
		if(refTopBar){
			refTopBar.current.refreshProfile();
		}
	}
  }
  
  const updateProfile = async () => {
    if (!newProfileName || !newProfileGrade) {
      alert('Please fill in all fields');
      return;
    }
    const _res = await AppHelper.apiPost('api/profile/update', {
      grade: newProfileGrade,
	  profileId:updatedProfileId,
      name: newProfileName,	  
	  email: oUser.email,
	  email1:newEmail1,
	  email2:newEmail2,
      token: oUser.token
    });
    if (!_res.status) {
      alert('Failed to create profile: ' + _res.message);
      return;
    }
    alert('Profile updated successfully');
    setNewProfileName('');
    setNewProfileGrade('');
	setEmail1('');
	setEmail2('');
    closeCreateProfileDialog();
    fetchProfiles();
  
    if(oUser.profileId == updatedProfileId){		 
      oUser.name =  newProfileName;
      oUser.grade = newProfileGrade;
	  oUser.email1 = newEmail1;
	  oUser.email2 = newEmail2;
	  setGrade(oUser.grade);
      AppHelper.userCacheUpdate(oUser);
    }
	
  };
  
  const updateAccountType = async () => {
    if (!accountType) {
      alert('Please fill in all fields');
      return;
    }
    const _res = await AppHelper.apiPost('api/account/updateAccountType', {
      accountType:accountType,
      token: oUser.token
    });
    if (!_res.status) {
      alert('Profile updated successfully');
      return;
    }
    oUser.accountType =  accountType;
		AppHelper.userCacheUpdate(oUser);
		setIsUpdatingAccountType(false);
  };
  
  useEffect(()=>{
    // const versionCheck = async ()=>{
    //   var _res = await AppHelper.api('api/version');
    //   if(_res && _res.status===1 && _res.data){
    //     setAppVer(_res.data.v);
    //   }
    // };
    // versionCheck();
  }, []);
  const gradeSelect = async(arg) =>{
    setGrade(arg.target.value);
    var _res = await AppHelper.apiPost('api/profile/update/grade', {token: oUser.token, grade: arg.target.value});
    if(!_res.status){
      alert('ERROR: Update failed!');
      return;
    }
    oUser.grade = arg.target.value;
    AppHelper.userCacheSet({...oUser});
  };
  const go2Home = ()=>{
    navigate('/home');
  };
  const go2ScoreRule = ()=>{
    navigate('/essay/score/rule');
  };
  const clearBrowserCache = ()=>{
    if(window && 'caches' in window){
      caches.keys().then((names) => {
        names.forEach(name => {
            caches.delete(name);
        })
      })
    }
    window.location.reload(true);
  };
  
  const openEditAccountTypeDialog = () => {
    setIsUpdatingAccountType(true);
  };
  const closeEditAccountTypeDialog = () => {
    setIsUpdatingAccountType(false);
  };

  const confirmDelete = async (profileId) => {
	  const confirmed = window.confirm("Are you sure you want to delete this profile?");
    if(!confirmed) return;
    try {
      const response = await AppHelper.apiPost('api/profile/remove', {
      profileId,
      token: oUser.token
      });
      if (response.status) {
      alert('Profile deleted successfully');
      fetchProfiles(); // Update the profiles list after deletion
      } else {
      alert('Failed to delete profile: ' + response.message);
      }
    } catch (error) {
      console.error('Error deleting profile:', error);
    }
	};
  const accountTypeSelect = async(arg)=>{
    const _res = await AppHelper.apiPost('api/account/updateAccountType', {
      accountType:arg.target.value,
      token: oUser.token
    });
    if (!_res.status) {
      alert('Account Type update failed');
      return;
    }
    setAccountType(arg.target.value);
    oUser.accountType =  arg.target.value;
		AppHelper.userCacheUpdate(oUser);
  }
  
  const onEmailChange = async(arg, type)=>{
    
	if(type == 1)
	{
		setEmail1(arg.target.value);
		oUser.email1 =  arg.target.value;
	}
	else if(type == 2)
	{
		setEmail2(arg.target.value);
		oUser.email2 =  arg.target.value;
	}
	AppHelper.userCacheUpdate(oUser);
  }
  
   const onEmailSave = async(type)=>{
	  
	   
    const _res = await AppHelper.apiPost('api/account/updateEmail', {
      email1:email1,
	  email2:email2,
	  profileId:oUser.profileId,
	  emailType:type,
      token: oUser.token
    });
    if (!_res.status) {
      alert('Email update failed');
      return;
    }
	else{
		 alert('Email update success');
	}
    
	if(type == 1)
		oUser.email1 =  email1;
	else if(type == 2)
		oUser.email2 =  email2;
	AppHelper.userCacheUpdate(oUser);
  }
  const handleClose = () => {
    setOpenSnackBar(false);
  }
  return (
  <div>
    <AppTopBar ref={refTopBar}/>
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Paper sx={{ 
        my: { 
          xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'45vh', xs:'70vh'}, 
          //backgroundColor:'rgb(235, 217, 180)' 
        }} elevation={3}>
        <nav aria-label="main mailbox folders">
          <List>
            {/* <ListItem>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={oUser.accountType == "STU" ? "Student": oUser.accountType=="TEA" ? "Tutor": "Parent"}   />
              <IconButton onClick={() => openEditAccountTypeDialog()}>
                <EditIcon />
              </IconButton>
            </ListItem> */}
            <ListItem>
              <ListItemAvatar>
              {/* <Avatar>
                <AccountBoxIcon />
              </Avatar> */}
                <Avatar src={`data:image/png;base64, ${oUser.picture}`} onClick={() => openChangePictureDialog(profiles.find(x=>x.profileId 
                  === oUser.profileId), true)} referrerPolicy="no-referrer" />
              {/* <img src={oUser.picture} referrerpolicy="no-referrer" /> */}
            </ListItemAvatar>
            <ListItemText primary={oUser.name}  secondary={(oUser.metadata && oUser.metadata.lastSignInTime) ? oUser.metadata.lastSignInTime : '' } />
              <IconButton onClick={() => openEditDialog(profiles.find(x=>x.profileId 
                === oUser.profileId))}>
                <EditIcon />
              </IconButton>
          </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <MailOutlineIcon sx={{ml: '10px'}}/>
                </ListItemIcon>
                <ListItemText primary={oUser.email} />
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <PermPhoneMsgIcon />
                </ListItemIcon>
                <ListItemText primary={oUser.phoneNumber} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CalendarMonthIcon />
                </ListItemIcon>
                <ListItemText primary={calendarActive} />
              </ListItemButton>
            </ListItem> */}
          </List>
        </nav>
        {/* <Divider /> */}
        {/* <nav aria-label="App Version">
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={'Last Login: ' + ((oUser.metadata && oUser.metadata.lastSignInTime) ? oUser.metadata.lastSignInTime : '')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary={appVer} sx={{color:'rgba(0,0,0,0.3)', textAlign:'right'}}  primaryTypographyProps={{fontSize: '0.7rem', fontStyle:'italic'}}/>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="#simple-list">
                <ListItemText primary="Spam" />
              </ListItemButton>
            </ListItem>
          </List>
        </nav> */}
        <Box m={.5} pt={1.5}>
          { accountType != 'TUI' && accountType != 'PAR' && gradeList && gradeList.length>0 &&
          <FormControl fullWidth error={!gradeOK}>
            <InputLabel id='lblGrade'>GRADE</InputLabel>
            <Select labelId='lblGrade'
              value={grade}
              onChange={gradeSelect}
              label="GRADE"
            >
              {gradeList.map((item) => (
                <MenuItem key={item.v} value={item.v}>
                  {item.n}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          }
        </Box>
        <Box m={.5} pt={1.5}>
          <FormControl fullWidth error={!gradeOK}>
            <InputLabel id='lblAccType'>ACCOUNT TYPE</InputLabel>
            <Select labelId='lblAccType'
              value={accountType}
              onChange={accountTypeSelect}
              label="ACCOUNT TYPE"
            >
              {accountTypeList.map((item) => (
                <MenuItem key={item.v} value={item.v}>
                  {item.n}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        { accountType != 'TEA' && accountType != 'TUI' &&
        <>
        <Box m={.5} pt={1.5}>  
          <TextField
          onChange={(event)=>onEmailChange(event, 1)}
            margin="dense"
            label="Student Email"
            value={email1}
              type="email"
            fullWidth
            InputProps={{endAdornment: <SaveIcon onClick={()=>onEmailSave(1)} sx={{cursor:'pointer'}} />}}
          />
        </Box>
        <Box m={.5} pt={1.5}>
          <TextField
          onChange={(event)=>onEmailChange(event, 2)}
            margin="dense"
            label="Parent Email"
            value={email2}
              type="email"
            fullWidth
            InputProps={{endAdornment: <SaveIcon onClick={()=>onEmailSave(2)} sx={{cursor:'pointer'}} />}}
          />
        </Box>
        </>
        }
        {/* <Divider sx={{marginTop:'1rem'}} /> */}
        <Box pt={1}>
          <Grid container>
            <Grid item xs={6} paddingLeft={'0.5rem'}>
              { profiles && profiles.length>1 && (
                <h3>Student Profiles</h3>
              )}
            </Grid>
            <Grid item xs={6} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
              { accountType != 'STU' &&
              <Button className="app-btn-bg" sx={{ marginBlockEnd: '0' }} onClick={openCreateProfileDialog} color="info" variant="contained" size="large">
                + Profile
              </Button>
              }
            </Grid>
          </Grid>
          <Box>
      {loading && <CircularProgress />}
        {!loading && <List>
              {profiles.map((profile) => {
        if (profile.profileId === oUser.profileId) {
        return null; // Skip rendering if the profile email matches the current user's email
        }
        return (
        <ListItemButton key={profile.profileId} >
			<ListItemAvatar onClick={profile.isOwnProfile ? () => openChangePictureDialog(profile) : undefined}>
				<Avatar src={`data:image/png;base64, ${profile.photo}`} referrerPolicy="no-referrer" />
			</ListItemAvatar>

          <ListItemText onClick={() => handleSwitchProfile(profile.name, profile.profileId)} 
            primary={profile.name} 
            secondary={<>
              <Typography
                component="span"
                variant="body2"
                style={{ display: 'block' }}
              >
                {profile.grade}
                {/* {accountType=='TUI'?'TUITION CENTER': accountType=='TEA' ? 'TUTOR' : accountType=='PAR' ? 'PARENT' : 'STUDENT'} */}
              </Typography>
              {/* <Typography
                component="span"
                variant="body2"
                style={{ display: 'block' }}
              >
                Type: {profile.grade}
              </Typography> */}
              <Typography
                component="span"
                variant="body2"
                style={{ display: 'block' }}
              >
              {profile.isOwnProfile ? `Created ${moment(profile.createdOn).fromNow()}` : null}


              </Typography>
            </>} />
            
            <Snackbar
              anchorOrigin={{vertical: 'top', horizontal: 'center' }}
              open={openSnackBar}
              autoHideDuration={3000}
              onClose={handleClose}
              message="Profile switched successfully!"
            />
          {profile.isOwnProfile ? (
		  <>
			<IconButton onClick={() => openEditDialog(profile)}>
			  <EditIcon />
			</IconButton>

			<IconButton onClick={() => confirmDelete(profile.profileId)}>
			  <DeleteIcon />
			</IconButton>
		  </>
		) : null}


        </ListItemButton>
        );
      })}
        </List>}
          </Box>
        </Box>
      <Box mt={3} display={'flex'} justifyContent={'center'}>
          <Button className="app-btn-bg"
            //sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} 
            onClick={go2ScoreRule} color="info" variant="contained" size="large">Scoring Rules</Button>
        </Box>
        <Box mt={0} display={'flex'} justifyContent={'center'}>
          <Button onClick={go2Home} color="info" variant="text" size="large">Back to Home</Button>
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <IconButton onClick={clearBrowserCache} aria-label="Refresh Browser Cache" title="Refresh Browser Cache">
            <PublishedWithChangesIcon sx={{color:'#888'}} />
          </IconButton>
        </Box>
        {/* <Box
          component="footer"
          sx={{ backgroundColor:'#fff', py: 1, px: 2, mt: 'auto', }}
        >
          <Container maxWidth="sm">
            <AppDataPolicy />
          </Container>
        </Box> */}
      </Paper>
      {/* <AppFooterFeedback/> */}
    </Container>
	  <Dialog open={isCreatingProfile || isUpdatingProfile} onClose={closeCreateProfileDialog}>
      <DialogTitle>{isUpdatingProfile ? 'Update Profile' : 'Create Profile'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          value={newProfileName}
          inputProps={{ maxLength: 45 }}
          onChange={(e) => setNewProfileName(e.target.value)}
        />
        <Box marginTop={2}>
          <FormControl fullWidth>
            <InputLabel id='lblGradeDialog'>Grade</InputLabel>
            { gradeList && gradeList.length>0 &&
            <Select
              labelId='lblGradeDialog'
              value={newProfileGrade}
              onChange={(e) => setNewProfileGrade(e.target.value)}
              label="Grade"
            >
              {gradeList.map((item) => (
                <MenuItem key={item.v} value={item.v}>
                  {item.n}
                </MenuItem>
              ))}
            </Select>
            }
          </FormControl>
        </Box>
      
      <Box marginTop={2}>
          <TextField
          margin="dense"
          id="email1"
          label="Student Email"
          type="text"
          fullWidth
          value={newEmail1}
          inputProps={{ maxLength: 45 }}
          onChange={(e) => setNewEmail1(e.target.value)}
        />
        </Box>
      
      <Box marginTop={2}>
          <TextField
          margin="dense"
          id="email2"
          label="Parent Email"
          type="text"
          fullWidth
          value={newEmail2}
          inputProps={{ maxLength: 45 }}
          onChange={(e) => setNewEmail2(e.target.value)}
        />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeCreateProfileDialog}>Cancel</Button>
        <Button
          sx={{
            backgroundColor: 'rgb(99, 136, 137)',
            ":hover": { backgroundColor: 'rgb(157, 188, 152)' }
          }}
          color="info"
          variant="contained"
          onClick={isUpdatingProfile ? updateProfile : createProfile}
        >
          {isUpdatingProfile ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={isUpdatingAccountType} onClose={closeAccountTypeDialog}>
      <DialogTitle>{'Update Account Type'}</DialogTitle>
      <DialogContent>
        
        <Box marginTop={2}>
          <FormControl fullWidth>
            <InputLabel id='lblAccountType'>Account Type</InputLabel>
            <Select
              labelId='lblAccountType'
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
              label="Account Type"
            >
              {accountTypeList.map((item) => (
                <MenuItem key={item.v} value={item.v}>
                  {item.n}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAccountTypeDialog}>Cancel</Button>
        <Button
          sx={{
            backgroundColor: 'rgb(99, 136, 137)',
            ":hover": { backgroundColor: 'rgb(157, 188, 152)' }
          }}
          color="info"
          variant="contained"
          onClick={updateAccountType}
        >
          {'Update'}
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog open={isChangingPicture} onClose={closeChangePictureDialog}>
      <DialogTitle>Change Profile Picture</DialogTitle>
      <DialogContent>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeChangePictureDialog}>Cancel</Button>
        <Button  sx={{
            backgroundColor: 'rgb(99, 136, 137)',
            ":hover": { backgroundColor: 'rgb(157, 188, 152)' }
          }}
          variant="contained" onClick={submitProfilePicture} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  </div>
  );
}
