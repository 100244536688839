import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { $getRoot, $getSelection, createEditor } from 'lexical';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { EssayHelper } from "../helpers/essay.helper";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $createTextNode } from 'lexical';
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar from './common/app-top-bar';
import AppLoading from './common/app-loading';
import '../css/property-list.css';
import {
  Dialog, DialogTitle, FormLabel, FormControlLabel, Paper, Button, Divider,
  Radio, RadioGroup, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useProperty } from '../contexts/context-property';
import { AppHelper } from '../AppHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ToolbarPlugin from './plugin/editor-toolbar';

export default function PageQuestion({
  onClose, open, model
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);  
  const [isSa, setIsSa] = useState(false);  
  const [oUser, setOUser] = React.useState(null);  
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal: 'center', msg: ''
  });
  const [questionData, setQuestionData] = useState({
    text: '',
    files: [],
    grade: '',
    title: '',
    topic: '',
    transcribe: ''
  });

  const [gradeList, setGradeList] = useState([]);
  const [txtNameOK, setTxtNameOK] = useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [grade, setGrade] = useState('P5');
  const [ownership, setOwnership] = useState(0);
  const [saQuestion, setSaQuestion] = useState(0);
  const [title, setTitle] = useState('');
  const [topic, setTopic] = useState('');
  const [authOK, setAuthOK] = React.useState(false);
  const questionTypes = [
    { code: '-', name: '-- Select --' },
    { code: 'BUG', name: 'BUG REPORT' },
    { code: 'FEATURE', name: 'FEATURE REQUEST' },
    { code: 'GENERAL', name: 'GENERAL QUESTION' },
  ];
  const [question, setQuestion] = useState('');
  const [questionType, setQuestionType] = useState('-');
  const [questionTypeOK, setQuestionTypeOK] = useState(true);
  const [questionDataType, setQuestionDataType] = useState('text');
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [transcribe, setTranscribe] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  
  const fetchIAM = async(oUser)=>{
      var _res = await AppHelper.apiPost('api/account/iam', {token: oUser.token});
      if(!_res || !_res.status || !_res.data || !_res.data.isSa){
        return;
      }
      setIsSa(true);
    }
	
  const fetchImages = async (questionId) => {
    
    var _res = await AppHelper.apiPost('api/question/detail', { token: oUser.token, questionId: questionId });
    if (!_res || !_res.status) {
      setImageUrls([]);
    }
    else {

      
      setImageUrls(_res.question.imagesBase64);
    
    }
  };

  function htmlToLexicalNodes(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const root = $getRoot();

    function parseNode(node) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node;

        if (element.nodeName === 'P') {
          const paragraphNode = $createParagraphNode();
          root.append(paragraphNode);
          Array.from(element.childNodes).forEach(child => parseNode(child));
        } else if (element.nodeName === 'STRONG') {
          // Handle <strong> element
          const parent = root.getLastChild();
          if (parent) {
            Array.from(element.childNodes).forEach(child => {
              if (child.nodeType === Node.TEXT_NODE) {
                const textNode = $createTextNode(child.textContent || '');
                textNode.toggleFormat('bold'); // Mark as bold in Lexical

                // Apply custom class
                textNode.setStyle('class', 'editor-text-bold');
                parent.append(textNode);
              }
            });
          }
        } else if (element.nodeName === 'SPAN') {
          const textNode = $createTextNode(element.textContent || '');
          const parent = root.getLastChild();
          if (parent) {
            parent.append(textNode);
          }
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        const textNode = $createTextNode(node.textContent || '');
        const parent = root.getLastChild();
        if (parent) {
          parent.append(textNode);
        }
      }
    }

    // Start parsing from the root
    Array.from(doc.body.childNodes).forEach(parseNode);
  }

  function InitialContentPlugin({ initialContent }) {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
      if (isEdit) {
        editor.update(() => {
          const root = $getRoot();

          if (root.getChildren().length > 0) {

            htmlToLexicalNodes(initialContent);
          }

        });
        setIsEdit(false);
      }
    }, [editor, initialContent]);

    return null;
  }
  const fetchGrades = async (oUser) => {
    var grade = await EssayHelper.essayGradeGet(oUser.token);
    setGradeList(grade);
  };

  const gradeSelect = async (arg) => {
    setGrade(arg.target.value);
    //setGradeOK(bufferTime>0);
  };
  const ownershipSelect = async (arg) => {
    setOwnership(arg.target.value);
  };

  useEffect(() => {
    var _user = AppHelper.userCacheGet();
    setAuthOK(_user != null);
    setOUser(_user);	
    if (_user && _user.grade) {
      fetchGrades(_user);
	  fetchIAM(_user);
    }
  }, []);

  useEffect(() => {
    if (model) {
      setGrade(model.grade);
	  setTitle(model.title);
	  setTopic(model.topic);	
	  setImageUrls([]);
	  setOwnership(model.uploadType);	  
	  setSaQuestion(model.saQuestion);
      setIsEdit(true);
	  
	  questionData.title = model.title;
	  questionData.grade = model.grade;
	  questionData.text = model.topic;

	  if(model.images && model.images > 0)
	  {
		fetchImages(model.questionId);
		setQuestionDataType("image");
	  }
	  else setQuestionDataType("text");
      //editorRef.current.innerHTML = model.topic;
    }
  }, [model]);

  useEffect(() => {
  }, []);
  const onSelectDdl = (event) => {
    var _newValue = event.target.value;
    setQuestionType(_newValue);
    if (_newValue !== '-') {
      setQuestionTypeOK(true);
    }
    else setQuestionTypeOK(false);
  };
  const onUpdate = async () => {
    if (!isDataOK()) return;
    // var _data = {
    //   uid: oUser.uid,
    //   pid: property.pid,
    //   n: txtName.current.value,
    //   s: txtSeller.current.value,
    //   sp: formattedPhoneNumber,
    //   pc: txtPostalCode.current.value,
    //   address: address
    // }
    setIsSubmitting(true);
    var _res = await AppHelper.api('api/email', 'POST', { email: oUser.email, name: oUser.displayName, questionType, questionMsg: question });
    setIsSubmitting(false);
    if (_res.status != 1) {
      setMsgBox({ ...msgBox, open: true, msg: 'ERROR: Submission failed!' });
      return;
    }
    setMsgBox({ ...msgBox, open: true, msg: 'SUCCESS: Question submitted!' });
    setTimeout(() => {
      goBack();
    }, 1000 * 1);
    //navigate('/property/detail/' + property.pid);
  }
  const goBack = () => {
    window.history.back();
  };
  const isDataOK = () => {
    var _validateOK = true;
    console.log(question);
    if ((!question || question.length < 1)) {
      _validateOK = false;
      setTxtNameOK(false);
      setMsgBox({ ...msgBox, open: true, msg: 'Please enter the question message!' });
    }
    else setTxtNameOK(true);
    if (!questionType || questionType.length < 1 || questionType === '-') {
      setQuestionTypeOK(false);
      _validateOK = false;
    }
    else setQuestionTypeOK(true);

    return _validateOK;
  };
  const onChangeQuestion = (arg) => {
    setQuestion(arg.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await handleQuestionSubmit();
    setLoading(false);
  };

  const handleQuestionSubmit = async () => {
    console.log('textContent', textContent, editorRef.current);
    // const root = $getRoot(); // Get the root of the editor
    // const currentText = root.getTextContent(); // Get the text content from the root
    // setTextContent(currentText); // Store the text in the state
    // console.log("Submitted Text:", currentText); // Log the submitted text
    let editorContent = "";
    if (editorRef.current) {
      editorContent = editorRef.current.innerHTML;

      // editorRef.current.update(() => {
      //   const currentText = editorRef.current.getRoot().getTextContent(); // Get the latest text content
      //   setTextContent(currentText); // Update state with the latest text
      //   console.log("Submitted Text:", currentText); // Log the submitted text
      // });
    }
    // if (editor) {
    //   editor.update(() => {
    //     const root = editor.getRoot();
    //     const currentText = root.getTextContent(); // Get the latest text content
    //     setTextContent(currentText); // Update the state with the latest text
    //     console.log("Submitted Text:", currentText); // Log or send the text to your API
    //   });
    // }
    
    if (AppHelper.isEmptyString(questionData.title)) {
      alert('Title is required');
      return;
    }
    //editorContent == ""
    const _topic = questionData.text;
    if (questionDataType == 'text' && AppHelper.isEmptyString(_topic)) {
      alert('Topic is required');
      return;
    }
	
	if(!model){
		if (questionDataType == 'image' && AppHelper.isEmptyString(questionData.transcribe)) {
		  alert('Upload image is required');
		  return;
		}
	}

    const images = [];
    var _url = 'api/question';
    if (model) _url = 'api/question/update';

    var _user = AppHelper.userCacheGet();

    if (questionData.files.length == 0) {

      var _res = await AppHelper.apiPost(_url, {
        token: _user.token, 
        title: questionData.title, topic: _topic, grade: grade, 
        b64: images, transcribe:questionData.transcribe, 
        questionId: model ? model.questionId : "",
        uploadType: ownership,
		saQuestion: saQuestion,
      });
      console.log('payload',{
        token: _user.token, 
        title: questionData.title, topic: _topic, grade: grade, 
        b64: images, transcribe:questionData.transcribe, 
        questionId: model ? model.questionId : ""
      });

      if (!_res || !_res.status) {
        alert('ERROR: Failed to submit question!');
        return;
      }
      else {
        setQuestionData({
          text: '',
          files: [],
		  title:'',

        });
        alert('Question submitted!');
        onClose(true);
      }
    }
    else {
      let totalSize = 0;

      // Calculate total size of all files
      for (let i = 0; i < questionData.files.length; i++) {
        totalSize += questionData.files[i].size;
      }

      // Check if total size exceeds 9MB (9 * 1024 * 1024 bytes)
      if (totalSize > 9 * 1024 * 1024) {
        alert('Total size of uploaded files cannot exceed 9MB.');
        return;
      }

      for (let i = 0; i < questionData.files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(questionData.files[i]);
        reader.onload = async () => {
          images.push(reader.result.split(',')[1]);
          if (images.length === questionData.files.length) {
            const questionWithImages = { ...questionData, images };

            var _res = await AppHelper.apiPost(_url, {
              token: _user.token, 
              title: questionData.title, topic: '-', grade: grade, 
              b64: images, 
			  uploadType: ownership,
			  saQuestion: saQuestion,
              transcribe:questionData.transcribe, 
              questionId: model ? model.questionId : ""
            });

            if (!_res || !_res.status) {
              console.log('_res', _res);
              alert('ERROR: Failed to submit question!');
              return;
            }
            else {
              setQuestionData({
                text: '',
                files: [],
				title:'',

              });
              alert('Question submitted!');
			   onClose(true);
            }

          }
        };
      }

      onClose(true);
    }
  };

  const handleClose = () => { onClose(false) };
  const editorConfig = {
    namespace: 'EA Editor',
    nodes: [],
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // The editor theme
    theme: {
      code: 'editor-code',
      heading: {
        h1: 'editor-heading-h1',
        h2: 'editor-heading-h2',
        h3: 'editor-heading-h3',
        h4: 'editor-heading-h4',
        h5: 'editor-heading-h5',
      },
      image: 'editor-image',
      link: 'editor-link',
      list: {
        listitem: 'editor-listitem',
        nested: {
          listitem: 'editor-nested-listitem',
        },
        ol: 'editor-list-ol',
        ul: 'editor-list-ul',
      },
      ltr: 'ltr',
      paragraph: 'editor-paragraph',
      placeholder: 'editor-placeholder',
      quote: 'editor-quote',
      rtl: 'rtl',
      text: {
        bold: 'editor-text-bold',
        code: 'editor-text-code',
        hashtag: 'editor-text-hashtag',
        italic: 'editor-text-italic',
        overflowed: 'editor-text-overflowed',
        strikethrough: 'editor-text-strikethrough',
        underline: 'editor-text-underline',
        underlineStrikethrough: 'editor-text-underlineStrikethrough',
      },
    },
  };
  //const editor = useLexicalEditor(editorConfig);
  const [textContent, setTextContent] = useState('');
  const editorRef = React.useRef(null);
  const onQuestionDataTypeChange = (event) => {
    setQuestionDataType(event.target.value);
    console.log(event.target.value);
  };
  
  const onSaQuestionChange = (event) => {
    setSaQuestion(event.target.value);
    console.log(event.target.value);
  };
  
  const onImgSelect = async(argEvent)=>{
    //setTranscribe('');
    if(!argEvent.target.files || argEvent.target.files.length<1){

      return;
    }
    setIsTranscribing(true);
    const reader = new FileReader();
    reader.readAsDataURL(argEvent.target.files[0]);
    reader.onload = async () => {
      //images.push(reader.result.split(',')[1]);
      // if (images.length === questionData.files.length) {
        
      // }
      var _user = AppHelper.userCacheGet();
      const _b64 = reader.result.split(',')[1];
      var _res = await AppHelper.apiPost('api/question/transcribe', { token: _user.token, b64: _b64});
      console.log(_res);
      //setTranscribe(_res.data);
      setIsTranscribing(false);
      setQuestionData({ ...questionData, transcribe: _res.data, files:argEvent.target.files });
    };
  }
  //const editorInstance = useLexicalEditor(editorConfig);
  // const updateTextContent = () => {
  //   // Get the editor state and extract the text content
  //   editorRef.current.getEditorState().read(() => {
  //       const root = editorRef.current.getEditorState().read();
  //       const text = root.getTextContent();
  //       setTextContent(text);
  //     });
  // };
  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogTitle>{model ? "EDIT QUESTION" : "ADD QUESTION"}</DialogTitle>
        <Box pl={2} pr={2} pb={2} alignItems="flex-start">

          <Grid>
            <Grid item xs={12} sm={6}>
              <Box m={.5} >
                <Grid item mb={0.5}>
                  <TextField
                    label="Question Title"
                    rows={4}
                    variant="outlined"
                    fullWidth
                    value={questionData.title}
                    onChange={(e) => setQuestionData({ ...questionData, title: e.target.value })}
                    />
                </Grid>
                <Grid item mt={1.5}>
                  {gradeList && gradeList.length > 0 &&
                    <FormControl fullWidth>
                      <InputLabel id="demo-select-small-label">GRADE</InputLabel>
                      <Select labelId='lblGrade'
                        value={grade}
                        onChange={gradeSelect}
                        label="GRADE"
                        inputProps={{
                          //disableUnderline: true
                        }}
                        sx={{ "& fieldset": {} }}
                        fullWidth
                      >
                        {gradeList.map((item) => (
                          <MenuItem key={item.v} value={item.v}>
                            {item.n}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </Grid>
                <Grid item mt={1.5}>
				  {saQuestion == 0 && (
					<FormControl fullWidth>
					  <InputLabel id="demo-select-small-label">OWNERSHIP</InputLabel>
					  <Select
						labelId="lblGrade"
						value={ownership}
						onChange={ownershipSelect}
						label="OWNERSHIP"
						inputProps={{
						  // disableUnderline: true
						}}
						sx={{ "& fieldset": {} }}
						fullWidth
					  >
						<MenuItem value={0}>SELF</MenuItem>
						<MenuItem value={1}>TUITION CENTER</MenuItem>
					  </Select>
					</FormControl>
				  )}
				</Grid>
                <Grid item xs={12}>
                  <FormControl>
                    {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                    <RadioGroup row
                      value={questionDataType}
                      onChange={onQuestionDataTypeChange}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel value="text" control={<Radio />} label="TEXT" />
                      <FormControlLabel value="image" control={<Radio />} label="IMAGE" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
				
				
				
                { questionDataType == 'text' &&
                <Grid item style={{ marginTop: '0' }}>
                  {/* <LexicalComposer initialConfig={editorConfig}>
                    <div className="editor-container">
                      <ToolbarPlugin />
                      <div className="editor-inner">
                        <RichTextPlugin
                          contentEditable={
                            <ContentEditable ref={editorRef}
                              className="editor-input"
                            />}
                          placeholder={<div>Enter question...</div>}
                          ErrorBoundary={LexicalErrorBoundary}
                        />
                        <HistoryPlugin />
                        <InitialContentPlugin initialContent={model ? model.topic : ""} />
                        <AutoFocusPlugin />
                      </div>
                    </div>
                  </LexicalComposer> */}
                  <TextField
                    label="Question Topic"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    value={questionData.text}
                    onChange={(e) => setQuestionData({ ...questionData, text: e.target.value })}
                    />
                </Grid>
                }
                { questionDataType == 'image' &&
                <Grid item style={{ marginTop: '16px' }}>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    //onChange={(e) => setQuestionData({ ...questionData, files: e.target.files })}
                    onChange={(e) => onImgSelect(e)}
                  />
                </Grid>
                }
				
				{imageUrls && imageUrls.length > 0 && 
				  imageUrls.map((imageUrl, index) => (
					<Grid item key={index} xs={6} sm={4} md={3}>
					  <img
						src={`data:image/jpeg;base64,${imageUrl}`}
						alt={`Image ${index + 1}`}
						style={{ maxWidth: '100%' }}
					  />
					</Grid>
				  ))
				}

				
				{ isSa &&
				<Grid item xs={12}>
				  <FormControl sx={{ marginTop: 2 }} component="fieldset" fullWidth>
					<FormLabel component="legend">System-wide Question</FormLabel> {/* Use FormLabel instead of InputLabel */}
					<RadioGroup
					  row
					  value={saQuestion}
					  onChange={onSaQuestionChange}
					  aria-labelledby="demo-row-radio-buttons-group-label"
					  name="row-radio-buttons-group"
					>
					  <FormControlLabel value="1" control={<Radio />} label="YES" />
					  <FormControlLabel value="0" control={<Radio />} label="NO" />
					</RadioGroup>
				  </FormControl>
				</Grid>
				}
				
              </Box>
            </Grid>
            <Grid container pt={.5}>
              <Grid item xs={6}>

              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  {/* <Button onClick={onAddSubmit} color="info" variant="outlined">NEXT</Button> */}
                  <LoadingButton
                    onClick={handleSubmit}
                    // endIcon={<SendIcon />}
                    loading={isSubmitting || isTranscribing}
                    //loadingPosition="start"
                    variant="contained"
                  >
                    <span>SUBMIT</span>
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>

      </Dialog>
    </>
  );
}
