import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { visuallyHidden } from "@mui/utils";
import { EssayHelper } from "../../helpers/essay.helper";
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar from '../common/app-top-bar';
import AppLoading from '../common/app-loading';
import '../../css/property-list.css';
import './sentence-transform-exercise.css';
import {
  Chip, Dialog, DialogTitle, FormLabel, FormControlLabel, Paper, Button, Divider,
  Radio, RadioGroup, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid, TableHead, TableBody, TableCell, TableContainer, TableSortLabel, TableRow, Table,
  CircularProgress
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { AppHelper } from '../../AppHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ToolbarPlugin from '../plugin/editor-toolbar';


export default function PopPreview({
  onClose, open, question
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const oUser = AppHelper.userCacheGet();
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal: 'center', msg: ''
  });
  const [questionData, setQuestionData] = useState({
    text: '',
    files: [],
    question: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [questionSet, setQuestionSet] = useState([]);
  const chipSampleColors = {
    '1': 'rgba(19,130,117,1)',
    '0': 'white'
  };

  useEffect(()=>{
    if(!open) return;
    fetchQuestionSet();
    console.log("Question", question);
  }, [question]);

  const fetchQuestionSet = async()=>{
    setIsLoading(true);
    var _user = AppHelper.userCacheGet();

    const _res = await AppHelper.apiPost('api/exercise/sentenceTransform/questionSet/get', {
                    token: _user.token,
                    questionSetId: question.questionSetId
                });

    const transformedQuestions = _res.data.map(item => ({
        questionNo: item.questionOrder, 
        questionId: item.questionSetItemId,
        question: transformToExerciseFormat(item.answer).replace(/<p>/g, '').replace(/<\/p>/g, ''),
        originalSentence: item.question,
        status: item.status,
        original_sentence: item.question, 
    }));

    setQuestionSet(transformedQuestions);
    setIsLoading(false);
    if (!_res || !_res.status) {
        alert('ERROR: Failed to fetch the question set!');
        return;
    }
  };

  function transformToExerciseFormat(answer) {
    const doc = new DOMParser().parseFromString(answer, 'text/html');
    const textWithUnderlines = doc.body.innerHTML.replace(/<code>/g, '<u>').replace(/<\/code>/g, '</u>');
    return textWithUnderlines;
  }

  const renderLine = (htmlString, listIndex) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return Array.from(doc.body.childNodes).map((node, nodeIndex) => {
        if (node.nodeName === 'U') {
            return (
                <span
                    key={`${listIndex}-${nodeIndex}`}
                    type="text"
                    className="txt-exercise"
                    // value={inputValues[listIndex] && inputValues[listIndex][nodeIndex] ? inputValues[listIndex][nodeIndex] : ''}
                    // onChange={(e) => handleInputChange(listIndex, nodeIndex, e.target.value)}
                    style={{ margin: '0 5px', backgroundColor: 'transparent', 
                             display: 'inline-block', width: '200px'
                     }}
                />
            );
        } else {
            return <span key={`${listIndex}-${nodeIndex}`}>{node.textContent || node.outerHTML}</span>;
        }
    });
  };


  
  const handleClose = () => { onClose() };
  return (
    <>
    {/* 21 March - the following code is commented because no pop up is needed since the preview window will always be on the right. 
    If no negative feedback related to this implementation, then the following code is able to be cleared.  */}
      {/* <Dialog onClose={handleClose} open={open} fullWidth
        maxWidth={false}
        sx={{ '& .MuiDialog-paper': { width: '75%', maxWidth: 'none' } }}
      > */}
          {/* <Button style={{ position: 'absolute',
                          top: 0,
                          right: 0,
                          margin: '10px',
                          color:"rgba(19,130,117,1)"  
                        }}
                  onClick={handleClose}
                  justifyItem="right">
            <CloseIcon/>
        </Button> */}
        <Grid>
        <DialogTitle>
          <Chip label={
            <span sx={{marginTop: '15px'}}>
              {oUser.grade}
              </span>
              } 
              variant="filled" 
              style={{
            backgroundColor: 'rgba(19,130,117,1)',
            color: 'white',
            height: '22px'
          }}
          sx={{ fontSize: '1.0rem', marginTop: '0rem', padding: '0.1px', marginLeft: '-7px', marginRight: '7px', marginBottom: '2px', 
            '& .MuiChip-label': {
              transform: 'translateY(1px)'
            }
           }}
          />
          {"QUESTION PREVIEW"} ({question.questionSet.questionSetName})</DialogTitle>

        <Box pl={2} pr={2} pb={2} alignItems="flex-start">
        {questionSet.map((rec,iRec)=>(
		    <div key={'exercise_new_' + iRec} style={{fontWeight:'normal', marginBottom:'.5rem'}}>
				<div><span style={{fontWeight:'bold'}}>Q: </span>{rec.originalSentence}</div>
				<div>
					<span style={{fontWeight:'bold'}}>A: </span>{renderLine(rec.question)}
				</div>
				<br />
			</div>
		))}

        </Box>
        </Grid>
      {/* </Dialog> */}
    </>
  );

}
