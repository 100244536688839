import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../../AppHelper';
import {
  Button, Box, Card, Dialog, DialogTitle, Grid, IconButton,
  Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { pink, green } from '@mui/material/colors';
import PopErrorAdd from "./pop-error-add";

function PopSPErrorsTable({onAction, rows, isLatest, title, onPopAddOpen, allowMark}){
  return(
    <Box >	
      <Box padding={'0.5rem'} marginBottom={'1rem'} sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
      <Box>{title}
        {/* <Button onClick={()=>onPopAddOpen(title)}>Add</Button> */}
      </Box>
      <TableContainer component={Paper}>
        <Table  aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight:'bold'}}>Wrong Spelling</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>Correct Spelling</TableCell>
              <TableCell sx={{fontWeight:'bold'}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { rows.map((row, iRow)=>(
              <TableRow key={`tr_${iRow}_E`} style={{textDecoration:row.isFalse==1?'line-through':''}}>
                <TableCell
                  dangerouslySetInnerHTML={{ __html: (isLatest && row.wl) ? row.wl : row.w }}>
                </TableCell>
                <TableCell
                  dangerouslySetInnerHTML={{ __html: (isLatest && row.rl) ? row.rl : row.r }}>
                </TableCell>
                <TableCell>
                    { allowMark && row.isFalse != 1 && 
                    <Tooltip title="Mark as False Positive">
                      <IconButton onClick={() => onAction(row, 1)} area-label="Mark as False Positive">
                        <RemoveCircleOutlineIcon style={{color:pink[500]}} />
                      </IconButton>
                    </Tooltip>
                    }
                    { allowMark && row.isFalse == 1 && 
                    <Tooltip title="Mark as Positive">
                      <IconButton onClick={() => onAction(row, 0)} area-label="Re-mark as Positive">
                        <TaskAltIcon style={{color:green[500]}} />
                      </IconButton>
                    </Tooltip>
                    }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
</Box>
  );
}

export default function PopSPErrors ({
  onClose, open, scoreInfo, essay, allowMark, onMarkDone
}){
  const [itemsL0, setItemsL0] = useState([]);
  const [itemsL1, setItemsL1] = useState([]);
  const [itemsL2, setItemsL2] = useState([]);
  const [isLatest, setIsLatest] = useState(false);
  const [popAddOpen, setPopAddOpen] = useState(false);
  const [popAddTitle, setPopAddTitle] = useState('');

  useEffect(() => {
    setItemsL0([]);
    setItemsL1([]);
    setItemsL2([]);
    if(!essay || !scoreInfo) return;
    var _isLatest = false;
    if(scoreInfo.levelItems0 && scoreInfo.levelItems0.length>0){
      var _items0 = [...scoreInfo.levelItems0];
      _isLatest = lineGetAll(_items0);
      setItemsL0(_items0);
    }
    if(scoreInfo.levelItems1 && scoreInfo.levelItems1.length>0){
      var _items1 = [...scoreInfo.levelItems1];
      _isLatest = lineGetAll(_items1);
      setItemsL1(_items1);
    }
    if(scoreInfo.levelItems2 && scoreInfo.levelItems2.length>0){
      //setItemsL2([...scoreInfo.levelItems2]);
      //if(scoreInfo.levelItems2[0].f) isLatest = true;
      var _items2 = [...scoreInfo.levelItems2];
      _isLatest = lineGetAll(_items2);
      setItemsL2(_items2);
    }
    setIsLatest(_isLatest);

  }, [essay, scoreInfo]);

  const lineGetAll = (argItems)=>{
    if(!argItems || argItems.length<1 || !argItems[0].f) return false;
    argItems.forEach(si => {
      si.wl = si.w;
      si.rl = si.r;
      var _lineGet = lineGet(si.f);
      if(_lineGet){
        var {line, lineC} = _lineGet;
        if(line){
          si.wl = line;
          si.rl = lineC;
        }
      }
    });
    return true;
  }
  const lineGet = (flag) =>{
    if(!flag || !essay || !essay.text) return null;
    const lines = essay.text.split('.');
    let line, lineC;
    let startIndex = 0;
    for (const l of lines) {
      const endIndex = startIndex + l.length;
      if (flag.offset >= startIndex && flag.offset <= endIndex) {
        var _sugg = (flag.suggestions && flag.suggestions.length>0) ? flag.suggestions[0].suggestion : '';
        var _iStart = flag.offset - startIndex;
        line = l.substring(0, _iStart)
          + `<span style="background:#ffe0b2; font-weight:bold">${flag.token}</span>`
          + l.substring(_iStart+flag.token.length, endIndex);
        lineC = l.substring(0, _iStart)
          + `<span style="background:#1de9b6; font-weight:bold">${_sugg}</span>`
          + l.substring(_iStart+flag.token.length, endIndex);
        break;
      }
      startIndex = endIndex + 1; // Add 1 for the period
    }
    return {line, lineC};
  };

  const handleClose = () =>{ onClose() };
  const removeError = () => {

  };
  
  const onFalseClick = async(arg, argValue) => {
	  var _user = AppHelper.userCacheGet();
    var _url = 'api/score/' + 'SP' + '/error/false'; 
	  var _payload = {token: _user.token, essayId: essay.essayId, errorIndex: arg.eIndex, isFalse:argValue, profileId: _user.profileId};
	  
	  var _res = await AppHelper.apiPost(_url, _payload);
	  if (!_res || !_res.status) {
		  alert('ERROR: Failed to set false!');
		  return;
	  }
    if(itemsL0 && itemsL0.length>0 && itemsL0.find(x=>x.eIndex==arg.eIndex)){
      itemsL0.find(x=>x.eIndex==arg.eIndex).isFalse = argValue;
    }
    else if(itemsL1 && itemsL1.length>0 && itemsL1.find(x=>x.eIndex==arg.eIndex)){
      itemsL1.find(x=>x.eIndex==arg.eIndex).isFalse = argValue;
    }
    if(itemsL2 && itemsL2.length>0 && itemsL2.find(x=>x.eIndex==arg.eIndex)){
      itemsL2.find(x=>x.eIndex==arg.eIndex).isFalse = argValue;
    }
    var _errorCount = itemsL0.filter(x=>x.isFalse!=1).length;
    _errorCount += itemsL1.filter(x=>x.isFalse!=1).length;
    _errorCount += itemsL2.filter(x=>x.isFalse!=1).length;
	  //alert("Success");
    if(onMarkDone) onMarkDone(_errorCount);
	};
  const onPopAddOpen = (arg)=>{
    setPopAddTitle(arg);
    setPopAddOpen(true);
  }
  const onPopAddClose = ()=>{
    setPopAddOpen(false);
  }
  
  return(
    <>
    {/* <Dialog onClose={handleClose} open={open}>
      <DialogTitle>SPELLING MISTAKES</DialogTitle> */}
      <Box padding={'0.5rem'}>
        <Grid container>
          <Grid item xs={12}>
            { itemsL0.length>0 && (
              <PopSPErrorsTable onAction={onFalseClick} rows={itemsL0} isLatest={isLatest} title={'EASY'} onPopAddOpen={onPopAddOpen} allowMark={allowMark} />
            )}
            { itemsL1.length>0 && (
              <PopSPErrorsTable onAction={onFalseClick} rows={itemsL1} isLatest={isLatest} title={'MEDIUM'} onPopAddOpen={onPopAddOpen} allowMark={allowMark} />
            )}
            { itemsL2.length>0 && (
              <PopSPErrorsTable onAction={onFalseClick} rows={itemsL2} isLatest={isLatest} title={'DIFFICULT'} onPopAddOpen={onPopAddOpen} allowMark={allowMark} />
            )}
          </Grid>
          
          {/* <Grid item xs={12} p={2} pt={0} pb={0.5}>
            <Button onClick={handleClose} sx={{width:'100%'}}>CLOSE</Button>
          </Grid> */}
        </Grid>
      </Box>
	  
    {/* </Dialog> */}
    <PopErrorAdd title={popAddTitle}
      open={popAddOpen} onClose={onPopAddClose}
      essay={essay}
    />
    </>
  );
}