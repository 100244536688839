import React, { useState, useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { alpha } from "@mui/material/styles";
import { CheckCircle, Delete, Block } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import AppTopBar2 from "./common/app-top-bar2";
import AppLoading from "./common/app-loading";
import {
  Avatar,
  AppBar,
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Fab,
  Container,
  Divider,
  Drawer,
  TablePagination,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Select,
  Toolbar,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import LinearProgress from "@mui/material/LinearProgress";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PhoneNumber from "libphonenumber-js";
import { AppHelper } from "../AppHelper";
import SpaCheck from "./common/spa-check";
import PopUsageList from './pop-usage-list';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

const dayjs = require("dayjs");

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
          fontSize={"0.7rem"}
          sx={{
            color: props.recSelected == 1 ? "#fff" : "text.secondary",
          }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCellsExercise = [
  { id: "rowIndex", numeric: true, disablePadding: false, label: "#", align: "center" },
  { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },
  { id: "name", numeric: false, disablePadding: false, label: "PROFILE" },
  { id: "grade", numeric: false, disablePadding: false, label: "GRADE", align: "center" },
  { id: "score", numeric: false, disablePadding: false, label: "SCORE", align: "center" },  
  { id: "exercise", numeric: false, disablePadding: false, label: "EXERCISE", align: "center" },  
  { id: "tCode", numeric: false, disablePadding: false, label: "SUBMITTED ON", align: "center" },  
  { id: "action", numeric: false, disablePadding: false, label: "ACTION", align: "center" },  
  
];


function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  console.log("props.gid", props.gid);
  const headCells = headCellsExercise;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function PageSaExerciseList() {
  // const theme = createTheme({
  //   typography: {
  //     fontFamily: 'Noto Sans',
  //   }
  // });
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const { pageExerciseId } = useParams();
  const isPhoneVerifed =
    oUser && oUser.phoneNumber && oUser.phoneNumber.length > 0;
  const today = new Date();

  const [isLoading, setIsLoading] = useState(true);
  const [exerciseList, setExerciseList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [originalProfileList, setOriginalProfileList] = useState([]);


  const otpRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [viewMode, setViewMode] = React.useState("LIST");
  const [refreshList, setRefreshList] = React.useState(false);
  const [recExercise, setRecExercise] = React.useState({});
  const [autoSelectId, setAutoSelectId] = React.useState("");
  const [countExercise, setCountExercise] = React.useState(0);

  const [tblOrderExercise, setTblOrderExercise] = React.useState("desc");
  const [tblOrderByExercise, setTblOrderByExercise] = React.useState("tCode");
  const [filterExercise, setfilterExercise] = React.useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);  
  const [isPopUsageOpen, setIsPopUsageOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    AppHelper.apiAuditPost("P-SA-EX", "");
  }, []);

  

  useEffect(() => {
    if (!oUser) {
      navigate("/login");
      return;
    }
  });




 
  useEffect(() => {
    setIsLoading(true);
  
    const fetchExerciseList = async () => {
      exerciseList.splice(0);
      setIsLoading(true);
      var _res = await AppHelper.apiPost("api/exercise/sentenceTransform/list/all", {
        token: oUser.token,
      });      
	
      if (!_res || !_res.status) {   
		// setIsLoading(false);
        return;
      }
      //_res.data = _res.data.filter(x=>x.essayId=='da6b655a-c74a-45e8-b80f-d132e645f089');
      console.log("sa exercise", _res.data);
      _res.data.forEach((r, iRec) => {
        var _ts = dayjs(r.timestampUtc);
        r.rowIndex = 0;
		r.exerciseCount = r.exerciseCount == 0 ? "-" : r.exerciseYCount + "/" + r.exerciseCount;
        r.tCode = _ts.format("YYYYMMDDHHmmss");
        r.tTitle = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` + _ts.format('DD MMM YYYY');
       
        r.email = r.profile ? r.profile.email : "-";
        r.name = r.profile ? r.profile.name : "-";
		r.grade = r.profile ? r.profile.grade : "-";
		
		let totalQuestions = 0;
        let totalStatus1 = 0;
		r.pages.forEach(page => {
            page.questions.forEach(question => {
                totalQuestions++;
                if (question.status === 1) {
                    totalStatus1++;
                }
            });
        });

        r.score = totalStatus1 + "/" + totalQuestions;
		
      });
      var _list = [...AppHelper.sortList(_res.data, "tCode", 2)];
      setExerciseList(_list);
      // setIsLoading(false);
    };       
    const fetchIAM = async () => {
      if (!oUser) {
        navigate("/login");
        return;
      }
      var _res = await AppHelper.apiPost("api/account/iam", {
        token: oUser.token,
      });
      if (!_res || !_res.status || !_res.data || !_res.data.isSa) {
        // setIsLoading(false);
        nonSaView();
        return;
      }
      fetchExerciseList();
    };
    fetchIAM();
    //fetchExerciseList();
    const fetchAccounts = async () => {
      setIsLoading(true);
      try {
            var _res = await AppHelper.apiPost("api/account/list/sa", {
              token: oUser.token,
            });
          setAccounts(_res.data); 
      } catch (error) {
          console.error('Error fetching accounts:', error);
      } finally {
          // setIsLoading(false);
      }
  };
  fetchAccounts();
  setIsLoading(false);
  }, [refreshList]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  const onExerciseSelect = (arg) => {
    setViewMode("VIEW");
    setRecExercise(arg);
    var _list = exerciseList;
    _list.forEach((s) => {
      s.selected = s.essayId == arg.essayId ? "1" : "0";
    });
  };
  const exerciseDelete = async (arg) => {
    if (!window.confirm("Are you sure you want to delete the Exercise?")) return;
    setIsLoading(true);
    var _res = await AppHelper.apiPost("api/exercise/sentenceTransform/delete", {
      token: oUser.token,
      exerciseId: arg.exerciseId,
    });
    if (!_res || !_res.status) {
      alert("ERROR: Failed to delete!");
      setIsLoading(false);
      return;
    }
    setRefreshList(!refreshList);
  };
  
  const exerciseListView = () => {
    //setViewMode("LIST");
    navigate("/saV2/exercise");
  };

  const cardView = () => {

    navigate("/sa");
  };
  const nonSaView = () => {
    setViewMode("NON-SA");
  };
  const drawerItemColrs = {
    "1x": "rgba(0,0,0,0.25)",
    "1xx": "rgb(157, 188, 152)",
    1: "rgb(99, 136, 137)",
    "0x": "white",
    0: "rgb(235, 217, 180)",
  };
  const drawerItemColorsText = {
    1: "rgba(255,255,255,1)",
    0: "rgba(0,0,0,0.6)",
  };
  const drawer = (
    <>
      <div style={{ overflowY: "auto", marginBottom: "110px" }}>
        <Box width={"100%"}>
          <List sx={{ fontFamily: "Noto Sans" }}>
            {/* {['Week 12 - Exercise B', 'Week 12 - Exercise A', 'Week 11 - Exercise B', 'Week 11 - Exercise A'].map((text, index) => ( */}
            {exerciseList.map((rec, index) => (
              <React.Fragment key={rec.essayId}>
                <ListItem
                  sx={{
                    backgroundColor: rec.selected
                      ? drawerItemColrs[rec.selected]
                      : drawerItemColrs["0"],
                    color: rec.selected
                      ? drawerItemColorsText[rec.selected]
                      : drawerItemColorsText["0"],
                  }}
                  disablePadding
                >
                  <ListItemButton onClick={() => onExerciseSelect(rec)}>
                    <div style={{ widht: "100%", flex: 1 }}>
                      <Box>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {rec.viewTitle}
                        </Typography>
                      </Box>
                      <div>
                        <LinearProgressWithLabel
                          value={rec.avgScoreP}
                          recSelected={rec.selected}
                        />
                      </div>
                      <Typography
                        sx={{
                          display: "inline",
                          color: rec.selected
                            ? drawerItemColorsText[rec.selected]
                            : drawerItemColorsText["0"],
                          opacity: "0.8",
                        }}
                        component="span"
                        variant="body2"
                      >
                        {rec.tTitle}
                      </Typography>
                    </div>
                  </ListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 50,
          right: 0,
          left: 0,
          height: "60px",
          borderTop: "0 solid rgba(0,0,0,0.12)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Button sx={{
        backgroundColor:'rgb(99, 136, 137)', 
        ":hover":{backgroundColor:'rgb(157, 188, 152)'},
        width:'100%',
        margin:'0 5px 22px 5px'
        }}
        onClick={essayAddClick} color="info" variant="contained" size="large">
        + ADD
      </Button> */}
      </div>
    </>
  );
  const mobileOpenClick = (arg) => {
    setMobileOpen(!mobileOpen);
  };
  const back2Home = () => {
    navigate("/");
  };
  const listSortAndfilterExercise = () => {
	setIsLoading(true);
    var _list = [...exerciseList];
    if (filterExercise && filterExercise.length > 0) {
      var _filterText = filterExercise.toLowerCase();
      _list = _list.filter(
        (r) =>
          r.type.toLowerCase().includes(_filterText) ||
          r.email.toLowerCase().includes(_filterText) ||          
		  r.name.toLowerCase().includes(_filterText) ||
		  r.grade.toLowerCase().includes(_filterText)
      );
    }


    

	setPage(0);

    const res = stableSort(_list, getComparator(tblOrderExercise, tblOrderByExercise));
	
	setIsLoading(false);
	
	return res;
  };
  const filterExerciseChange = (event) => {
    setfilterExercise(event.target.value);
  };

  const visibleExercises = React.useMemo(
	() => listSortAndfilterExercise(),
	
    [exerciseList, tblOrderExercise, tblOrderByExercise, filterExercise]
  );
  const tblSortExercise = (event, property) => {
    const isAsc = tblOrderByExercise === property && tblOrderExercise === "asc";
    setTblOrderExercise(isAsc ? "desc" : "asc");
    setTblOrderByExercise(property);
  };

  const handleViewUsageClick = (row) => {
    const accountId = accounts.find(account => account.profileId === row.profileId)?.accountId;
    if (accountId) {
      // console.log("Selected Account ID: ", accountId);
      setSelectedAccount(accountId);
      setIsPopUsageOpen(!isPopUsageOpen);
    } else {
      console.log("No matching account found for profile ID: ", row.profileId);
    }
  };

  const popUsageListClose = (questionId) => {
    setIsPopUsageOpen(false);
  }



  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <AppTopBar2 mobileOpen={mobileOpen} mobileOpenClick={mobileOpenClick} />
      {isLoading ? (
        <AppLoading />
      ) : (
        <>
         
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              //width: { md: `calc(100% - ${drawerWidth}px)` },
              width: "100%",
              marginTop: "50px",
            }}
          >

            {viewMode == "LIST" && (
              <>
                <Grid container alignItems="center" justifyContent="space-between">
				  <Grid item xs={4} sm={4}>
					<Button onClick={cardView}>&laquo; Back to Overview</Button>
				  </Grid>

				  <Grid item xs={8} sm={8} style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
					<TextField
					  value={filterExercise}
					  onChange={filterExerciseChange}
					  id="txtfilterExercise"
					  label=""
					  variant="standard"
					  placeholder="Search"
					  style={{ minWidth: '300px' }}
					/>
					
					
				  </Grid>
				</Grid>

                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                   

                    <EnhancedTableHead
                      //numSelected={selected.length}
                      order={tblOrderExercise}
                      orderBy={tblOrderByExercise}
                      //onSelectAllClick={handleSelectAllClick}
                      onRequestSort={tblSortExercise}
                      rowCount={exerciseList.length}
                      gid="G1"
                    />
                    <TableBody>
                      {visibleExercises
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, iRec) => (
                          <TableRow
                            key={row.exerciseId}
                            sx={{
                              
                            }}
                          >
                            <TableCell align="center" sx={{pr:'2.3rem'}}>{iRec + 1}.</TableCell>
                            <TableCell align="left">
                              {row.profile ? row.email : "-"}
                            </TableCell>
                            <TableCell align="left" sx={{pl:'0.5rem'}}>
                              <Button href={"/exercise/detail/" + row.exerciseId}
                                target="_blank"
                                style={{textTransform: 'none'}}>
                                {row.profile ? row.name : "-"}
                              </Button>
                              <IconButton
                                onClick={() => handleViewUsageClick(row)}
                                label="View usage"
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </TableCell>
							              <TableCell align="center" sx={{pr:'2.3rem'}}>
                              {row.profile ? row.grade : "-"}
                            </TableCell>
							
							<TableCell align="center" sx={{pr:'2.3rem'}}>
                              <Chip color="success" label={row.score} 
																				sx={{height:'20px', 
                                            backgroundColor:'rgb(19, 130, 117)', 
                                            color: 'rgb(255, 255, 255)', 
                                            marginTop: '1px'}}
															/>
                            </TableCell>
                           
							
							              <TableCell align="center"  sx={{pr:'2.3rem'}}>
                              {row.exerciseCount !== "-" && 
                                <Chip label={row.exerciseCount} 
                                          sx={{height:'20px', 
                                              backgroundColor:'rgb(19, 130, 117)', 
                                              color: 'rgb(255, 255, 255)', 
                                              marginTop: '1px'}}
                                />
                              }
                              {row.exerciseCount === "-" && 
                                <Chip label={row.exerciseCount} 
                                          sx={{height:'20px', 
                                              backgroundColor:'rgb(255, 255, 255)', 
                                              color: 'rgb(0, 0, 0)'}}
															  />}
                            </TableCell>
                           
                            
                            <TableCell align="center" sx={{pr:'2.3rem'}}>{row.tTitle}</TableCell>
                           
                            <TableCell align="center" sx={{pr:'2.3rem'}}>
                              <IconButton
                                onClick={() => exerciseDelete(row)}
                                aria-label="delete"
                              >
                                <DeleteForeverIcon color="error" />
                              </IconButton>
                              {/* <IconButton
                                href={"/exercise/detail/" + row.exerciseId}
                                target="_blank">
                              <OpenInBrowserIcon>
                              </OpenInBrowserIcon>
                              </IconButton> */}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={visibleExercises.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}





            
            {viewMode == "NON-SA" && (
              <React.Fragment>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h5" component="div">
                          Access denied
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          &nbsp;
                        </Typography>
                        <Typography variant="body2">
                          This page is only allowed for System Administrators.
                          {/* <br />
                    {'"a benevolent smile"'} */}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button onClick={back2Home} size="small">
                          Back to Home
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Box>
        </>
      )}
      <SpaCheck></SpaCheck>
      <PopUsageList
        open={isPopUsageOpen}
        accountId={selectedAccount}
        onClose={popUsageListClose}
      ></PopUsageList>
    </Box>
  );
}
