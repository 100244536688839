import React, { useState, useEffect } from "react";
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Code from '@tiptap/extension-code';
import { useNavigate } from 'react-router-dom';
import '../../css/property-list.css';
import {
  Dialog, DialogTitle, FormLabel, FormControlLabel, Paper, Button, Divider,
  Radio, RadioGroup, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { AppHelper } from '../../AppHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { EssayHelper } from "../../helpers/essay.helper";

const AnswerEditor = ({ initialContent, onUpdate }) => {
  const editor = useEditor({
    extensions: [StarterKit, Code],
    content: initialContent,
    editorProps: {
      attributes: {
        style: `
          border: 1px solid grey;
          border-radius: 5px;
          min-height: 80px;
          padding-left: 10px;
          padding-right: 10px;
        `,
      },
    },
    onSelectionUpdate: ({ editor }) => {
      const { from, to } = editor.state.selection;
      if (from !== to) {
        editor.commands.toggleCode();
      }
    },
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onUpdate(html);
    },
  });

  useEffect(() => {
    if (editor && initialContent !== editor.getHTML()) {
      editor.commands.setContent(initialContent);
    }
  }, [initialContent, editor]);

  return <EditorContent editor={editor} />;
};


export default function PageQuestion({ onClose, open, model, mode }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSa, setIsSa] = useState(false);  
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal: 'center', msg: ''
  });
  const [gradeList, setGradeList] = useState([]);
  const [oUser, setOUser] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

    const fetchIAM = async(oUser)=>{
        var _res = await AppHelper.apiPost('api/account/iam', {token: oUser.token});
        if(!_res || !_res.status || !_res.data || !_res.data.isSa){
          return;
        }
      setIsSa(true);
    }

  // Unified state management
  const [questionData, setQuestionData] = useState({
    title: '',
    grade: 'P5',
    questions: ['', '', '', '', ''],
    answers: ['', '', '', '', ''],
    uploadType: 0,
    saQuestion: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      const user = AppHelper.userCacheGet();
      if (!user) return;

      setOUser(user);
      const grades = await EssayHelper.essayGradeGet(user.token);
      setGradeList(grades || []);
      fetchIAM(user);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (model) {
      const questions = [];
      const answers = [];
      Object.keys(model).forEach(key => {
        if (!isNaN(key)) {
          questions.push(model[key].question);
          answers.push(model[key].answer);
        }
      })

      setQuestionData({
        title: model.questionSetName,
        grade: model.grade,
        questions: questions || Array(5).fill(''),
        answers: answers || Array(5).fill(''),
        uploadType: model.uploadType || 0,
        saQuestion: model.saQuestion || 0,
      });
      setIsEdit(true);
      
    }
	if (mode == 'add'){
	   setIsEdit(false);
	   setQuestionData({
        title: '',
        grade: 'P5',
        questions: Array(5).fill(''),
        answers: Array(5).fill(''),
        uploadType: 0,
        saQuestion: 0,
      });
	}
  }, [model, mode]);

  const handleQuestionChange = (value, index) => {
    const updatedQuestions = [...questionData.questions];
    updatedQuestions[index] = value;
    setQuestionData(prev => ({ ...prev, questions: updatedQuestions }));
  };

  const handleAnswerChange = (value, index) => {
    const updatedAnswers = [...questionData.answers];
    updatedAnswers[index] = value;
    setQuestionData(prev => ({ ...prev, answers: updatedAnswers }));
  };

  const handleSubmit = async () => {
    if (!questionData.title.trim()) {
      alert('Title is required');
      return;
    }
    if (!questionData.grade) {
      alert('Grade is required');
      return;
    }
    if (questionData.questions.some(q => !q.trim())) {
      alert('All questions must be filled');
      return;
    }
    if (questionData.answers.some(a => !a.trim())) {
      alert('All answers must be filled');
      return;
    }

    const payload = {
      token: oUser?.token,
      title: questionData.title,
      grade: questionData.grade,
      uploadType: questionData.uploadType,
      saQuestion: questionData.saQuestion,
    };

    questionData.questions.forEach((q, i) => payload[`question${i + 1}`] = q);
    questionData.answers.forEach((a, i) => payload[`answer${i + 1}`] = a);

    if (model) payload.questionSetId = model.questionSetId;

    setIsSubmitting(true);
    try {
      const url = model ? 'api/exercise/sentenceTransform/questionSet/update' 
                        : 'api/exercise/sentenceTransform/questionSet/create';
      const res = await AppHelper.apiPost(url, payload);
      
      if (!res?.status) throw new Error(res?.message || 'Submission failed');
      
      alert(`Question set ${model ? 'updated' : 'created'} successfully`);
      onClose(true);
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle>{model ? "EDIT QUESTION SET" : "ADD QUESTION SET"}</DialogTitle>
      <Box pl={2} pr={2} pb={2} alignItems="flex-start">
        <Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Question Set Title"
              rows={4}
              variant="outlined"
              fullWidth
              value={questionData.title}
              onChange={e => setQuestionData(prev => ({ ...prev, title: e.target.value }))}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={1.5}>
            <FormControl fullWidth>
              <InputLabel>Grade</InputLabel>
              <Select
                value={questionData.grade}
                onChange={e => setQuestionData(prev => ({ ...prev, grade: e.target.value }))}
                label="Grade"
              >
                {gradeList.map((grade) => (
                  <MenuItem key={grade.v} value={grade.v}>
                    {grade.n}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} mt={1.5}>
            <FormControl fullWidth>
              <InputLabel>Ownership</InputLabel>
              <Select
                value={questionData.uploadType}
                onChange={e => setQuestionData(prev => ({ ...prev, uploadType: e.target.value }))}
                label="Ownership"
              >
                <MenuItem value={0}>Self</MenuItem>
                <MenuItem value={1}>Tuition Center</MenuItem>
              </Select>
            </FormControl>
          </Grid>


          {questionData.questions.map((question, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} mt={1.5}>
                <FormLabel>Question {index + 1}</FormLabel>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  value={question}
                  onChange={e => handleQuestionChange(e.target.value, index)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel mb={0}>Answer {index + 1}</FormLabel>
                <AnswerEditor
                  initialContent={questionData.answers[index]}
                  onUpdate={content => handleAnswerChange(content, index)}
                />
              </Grid>
              {index < 4 && <Grid item xs={12}><Divider /></Grid>}
            </React.Fragment>
          ))}

          { isSa &&
            <Grid item xs={12} mt={1.5}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel>System-wide Question</FormLabel>
                <RadioGroup
                  row
                  value={questionData.saQuestion}
                  onChange={e => setQuestionData(prev => ({ ...prev, saQuestion: e.target.value }))}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel value={0} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
          }

          <Grid item xs={12} sx={{ mt: 2 }} display="flex" justifyContent="flex-end">
            <LoadingButton
              variant="contained"
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              {model ? "UPDATE" : "SUBMIT"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

