import React, { useState, useEffect, useRef } from "react";
import { AppHelper } from '../AppHelper';
import CloseIcon from '@mui/icons-material/Close';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead, 
  TableSortLabel,
  Box,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  CircularProgress,
  Button
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DescriptionIcon from '@mui/icons-material/Description';
import PageQuestion from './question';
import { visuallyHidden } from '@mui/utils';

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCellsEssay = [
  { id: 'createdOn', numeric: false, disablePadding: false, label: 'TIMESTAMP' },
  { id: 'uploadType', numeric: false, disablePadding: false, label: 'OWNERSHIP' },
  { id: 'title', numeric: false, disablePadding: false, label: 'TITLE' },
  { id: 'topic', numeric: false, disablePadding: false, label: 'TOPIC' },
  { id: 'grade', numeric: false, disablePadding: false, label: 'GRADE' },
  { id: 'images', numeric: false, disablePadding: false, label: 'HAS IMAGE' },
  { id: 'createdByName', numeric: false, disablePadding: false, label: 'CREATED BY' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'ACTIONS' }

];

function ImageDialog({ open, imageUrls, onClose, topic }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>View Question</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={3}>

          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: topic }} />
          </Grid>

          {imageUrls ? (
            imageUrls.map((imageUrl, index) => (
              <Grid item key={index} xs={6} sm={4} md={3}>
                <img
                  src={`data:image/jpeg;base64,${imageUrl}`}
                  alt={`Image ${index + 1}`}
                  style={{ maxWidth: '100%' }}
                />
              </Grid>
            ))
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </DialogContent>
      <Button onClick={onClose} style={{ position: 'absolute', top: 0, right: 0 }}>
        <CloseIcon />
      </Button>
    </Dialog>

  );
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = headCellsEssay;


  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function PageSaQuestionList({
  filterLog, popQuestionOpenParam, onClose
}) {
  const [popQuestionOpen, setPopQuestionOpen] = useState(false);
  const [tblOrderLog, setTblOrderLog] = React.useState('desc');
  const [tblOrderByLog, setTblOrderByEssay] = React.useState('tCode');
  const [isLoading, setIsLoading] = useState(true);
  const oUser = AppHelper.userCacheGet();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [isSa, setIsSa] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [logList, setLogList] = useState([]);
  const [refreshList, setRefreshList] = React.useState(true);
  const [countEssay, setCountLog] = React.useState(0);
  const popQuestionClose = (refresh) => {	
    setRefreshList(refresh);
    setPopQuestionOpen(false);
	if (onClose)
	{
		onClose(false);
	}
  }
  const handleCloseDialog = () => {
    setSelectedTopic("");
    setIsPopupOpen(false);
    setImageUrls([]);
  };
  const fetchIAM = async (oUser) => {
    var _res = await AppHelper.apiPost('api/account/iam', { token: oUser.token });
    if (!_res || !_res.status || !_res.data || !_res.data.isSa) {
      return;
    }
    setIsSa(true);
  }
  useEffect(() => {
    AppHelper.apiAuditPost("P-QU", "");
    var _user = AppHelper.userCacheGet();
    if (_user) {
      fetchIAM(_user);
	  
    }
  }, []);
  const questionDelete = async (arg) => {
    if (!window.confirm("Are you sure you want to delete the Question?")) return;
    setIsLoading(true);
    var _res = await AppHelper.apiPost("api/question/delete", {
      token: oUser.token,
      questionId: arg.questionId,
    });
    if (!_res || !_res.status) {
      alert("ERROR: Failed to delete!");
      setIsLoading(false);
      return;
    }
    setRefreshList(true);
  };
  
   const listSortAndFilterEssay = () => {
	   
    var _list = [...logList];
    if (filterLog && filterLog.length > 0) {
      var _filterText = filterLog.toLowerCase();
      _list = _list.filter(r =>
        r.topic.toLowerCase().includes(_filterText)
        || (r.title && r.title.toLowerCase().includes(_filterText))
        || (r.grade && r.grade.toLowerCase().includes(_filterText))
        || (r.createdByName && r.createdByName.toLowerCase().includes(_filterText))

      );
    }
    _list.forEach(r => {
      r.topicView = r.topic;
      const words = r.topicView.split(' ');
      if (words.length > 10) {
        r.topicView = words.slice(0, 10).join(' ') + '...';
      }
    });
    return stableSort(_list, getComparator(tblOrderLog, tblOrderByLog));
  };
  
  const visibleLogs = React.useMemo(
    () =>
      listSortAndFilterEssay()
    //stableSort(logList, getComparator(tblOrderLog, tblOrderByLog))
    // .slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // )
    ,
    //[order, orderBy, page, rowsPerPage],
    [logList, tblOrderLog, tblOrderByLog, filterLog]
  );
  const tblSortLog = (event, property) => {
    const isAsc = tblOrderByLog === property && tblOrderLog === 'asc';
    setTblOrderLog(isAsc ? 'desc' : 'asc');
    setTblOrderByEssay(property);
  };
  const questionEdit = async (row) => {
    setSelectedRow(row);
    setPopQuestionOpen(true);
  };
  const handleViewTopicClick = async (row) => {
    setSelectedRow(row);
    var _res = await AppHelper.apiPost('api/question/detail', { token: oUser.token, questionId: row.questionId });
    if (!_res || !_res.status) {
      setImageUrls([]);
      alert("No topic for this question");
      return;
    }
    else {

      console.log(_res.question.imagesBase64.length)
      setSelectedTopic(_res.question.topic);
      setImageUrls(_res.question.imagesBase64);
      setIsPopupOpen(true);
    }
  };
  
  const fetchLogList = async () => {
	var _res = await AppHelper.apiPost('api/account/iam', { token: oUser.token });
    logList.splice(0);
    setIsLoading(true);
    var url = 'api/question/list';

    if (_res && _res.status && _res.data && _res.data.isSa) {
      url = 'api/question/list/all';
    }
	else{
	  url = 'api/question/list';
	}

    var _res = await AppHelper.apiPost(url, { token: oUser.token, includeImage:0 });
    console.log('_res', _res);
    if (!_res || !_res.status) {
      setIsLoading(false);
      return;
    }
    var _list = [...AppHelper.sortList(_res.questionList, 'createdOn', 2)];
    setLogList(_list);
    setTimeout(() => { console.log('logList', _list); }, 1000);
    setIsLoading(false);
    setCountLog(_list.length);
    setRefreshList(false);
  };

  useEffect(() => {
    if(refreshList)
	{	
		fetchLogList();
	}

  }, [refreshList]);
  
  useEffect(() => {    
	setPopQuestionOpen(popQuestionOpenParam);
  }, [popQuestionOpenParam]);
  
  

  return (
  <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <EnhancedTableHead
          order={tblOrderLog}
          orderBy={tblOrderByLog}
          onRequestSort={tblSortLog}
          rowCount={logList.length}
          gid="G1"
        />
        <TableBody>
          {visibleLogs.map((row) => (
            <TableRow
              key={row.questionId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">
                {new Date(row.createdOn).toLocaleString()}
              </TableCell>
              <TableCell align="left">
                {row.saQuestion === 1
                  ? 'SYSTEM'
                  : row.uploadType === 0
                  ? 'SELF'
                  : `TUITION CENTER`}
              </TableCell>
              <TableCell align="left">{row.title}</TableCell>
              <TableCell align="left">
                <div dangerouslySetInnerHTML={{ __html: row.topicView }} />
              </TableCell>
              <TableCell align="left">{row.grade}</TableCell>
              <TableCell align="left">{row.images ? 'Y' : 'N'}</TableCell>
              <TableCell align="left">{row.createdByName}</TableCell>
              <TableCell>
                {!(!isSa && row.saQuestion === 1) && (
                  <IconButton
                    onClick={() => questionEdit(row)}
                    aria-label="edit"
                  >
                    <EditIcon />
                  </IconButton>
                )}
                <IconButton onClick={() => handleViewTopicClick(row)}>
                  <DescriptionIcon />
                </IconButton>
                {!(!isSa && row.saQuestion === 1) && (
                  <IconButton
                    onClick={() => questionDelete(row)}
                    aria-label="delete"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
	 <ImageDialog open={isPopupOpen} topic={selectedTopic} imageUrls={imageUrls} onClose={handleCloseDialog} />
	 <PageQuestion
        model={selectedRow}
        open={popQuestionOpen}
        onClose={popQuestionClose}
      />
   </>
  );
}
