import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import {
  Avatar, Chip, Dialog, DialogTitle, FormLabel, FormControlLabel, Paper, Button, Divider,
  Radio, RadioGroup, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid, TableHead, TableBody, TableCell, TableContainer, TableRow, Table,
  TableSortLabel, Tooltip, List, ListItem, ListItemAvatar, ListItemText, ListItemButton, ListItemIcon,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { AppHelper } from '../AppHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { create } from "@mui/material/styles/createTransitions";
import Autocomplete from '@mui/material/Autocomplete';
const dayjs = require("dayjs");

export default function PopLinkTutor({
  onClose, open, tutors, model, tuiton
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSa, setIsSa] = useState(false);
  const [oUser, setOUser] = React.useState(null);
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal: 'center', msg: ''
  });

  const [txtNameOK, setTxtNameOK] = useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [profileId, setProfileId] = useState('');
  const [authOK, setAuthOK] = React.useState(false);
  const [selectedTutor, setSelectedTutor] = useState();

  const fetchIAM = async (oUser) => {
    var _res = await AppHelper.apiPost('api/account/iam', { token: oUser.token });
    if (!_res || !_res.status || !_res.data || !_res.data.isSa) {
      return;
    }
    setIsSa(true);
  }

  const tutorSelect = async (arg) => {
    setProfileId(arg.profileId);
    setSelectedTutor(arg);
  };

  const filteredTutors = tutors.filter(
      (x) =>
        x.accountType === "TEA" &&
        !x.centerId &&
        x.type === "Profile"
  );
  useEffect(() => {
    var _user = AppHelper.userCacheGet();
    setAuthOK(_user != null);
    setOUser(_user);
    if (_user && _user.name) {
      fetchIAM(_user);
    }
  }, []);

  useEffect(() => {
    if (open) {
      setProfileId("");
    }
  }, [open]);

  useEffect(() => {
  }, []);

  const goBack = () => {
    window.history.back();
  };

  const handleSubmit = async () => {
    if (profileId == "") {
      alert("Tutor is required");
      return;
    }
    setLoading(true);

    var _res = await AppHelper.apiPost('api/profile/tutor/link', { token: oUser.token, profileId: profileId, centerId: tuiton });
    console.log('_res', _res);
    if (!_res || !_res.status) {
      setLoading(false);
      return;
    }
    alert("Link success");
    onClose(true);
    setSelectedTutor(); // To not show the Tutor that is linked (will not in tutor list) to be shown again in the pop-up. 
    setLoading(false);
  };



  const handleClose = () => { onClose(false) };


  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogTitle>{"LINK TUTOR"}</DialogTitle>
        <Box pl={2} pr={2} pb={2} alignItems="flex-start">

          <Grid>
            <Grid item xs={12} sm={6}>
              <Box m={.5} >

                <Grid item mt={1.5}>
                  {/* the following toggled code is replaced by the AutoComplete, 
                  if no negative feedback received, may clean the following code.  */}
                  {/* {tutors &&
                    <FormControl fullWidth>
                      <InputLabel id="">TUTOR</InputLabel>
                      <Select labelId='lblTutor'
                        value={profileId}
                        onChange={tutorSelect}
                        label="TUTOR"
                        inputProps={{
                          //disableUnderline: true
                        }}
                        sx={{ "& fieldset": {} }}
                        fullWidth
                      >
                        {tutors.filter((x) => x.accountType == "TEA" && !x.centerId && x.type == "Profile").map((item) => (
                          <MenuItem key={item.profileId} value={item.profileId}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  } */}
                  {tutors && (
                  <FormControl fullWidth>
                    <Autocomplete
                      options={filteredTutors}
                      value={selectedTutor}
                      onChange={(event, newValue) => {
                        tutorSelect(newValue ? newValue : "");
                      }}
                      getOptionLabel={(option) =>
                        `${option.name} (${option.email})`
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="TUTOR" />
                      )}
                    />
                  </FormControl>
                )}
                </Grid>

              </Box>
            </Grid>
            <Grid container pt={.5}>
              <Grid item xs={6}>

              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  {/* <Button onClick={onAddSubmit} color="info" variant="outlined">NEXT</Button> */}
                  <LoadingButton
                    onClick={handleSubmit}
                    // endIcon={<SendIcon />}
                    loading={isSubmitting}
                    //loadingPosition="start"
                    variant="contained"
                  >
                    <span>LINK</span>
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>

      </Dialog>
    </>
  );
}
