import React, { useEffect, useState, useRef } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AppHelper } from '../../AppHelper';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { pink, green } from '@mui/material/colors';
import { diffWordsWithSpace } from 'diff';
import {
  Button, Box, Chip, Dialog, DialogTitle, Grid, IconButton,
  Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from '@mui/material';

export default function PopVCXErrors ({
  onClose, open, scoreInfo, essay, allowMark, onMarkDone
}){
  //const [errorList, setErrorList] = useState([]);
  const [words, setWords] = useState([]);
  useEffect(()=>{
    console.log('VCX',scoreInfo);
    if(scoreInfo && scoreInfo.rareWords)
      setWords(scoreInfo.rareWords);
    //updateHighlight();
  }, [essay, scoreInfo]);
  // useEffect(()=>{
  //   updateHighlight();
  // }, [open]);
	
  const handleClose = () =>{ onClose() };
  
  const onFalseClick = async(rowIndex, argValue, type) => {
    let _words = [...words];
    let _word = _words.find(r=>r.iWord == rowIndex && r.t == type);
    if(!_word){
      alert('Word not found!');
      return;
    }
	  var _user = AppHelper.userCacheGet();
    var _url = 'api/score/VCX/error/false'; 
	  if(type == "U") type="UC";
	  else type = "RA";
	  var _payload = {token: _user.token, type:type, essayId: essay.essayId, errorIndex: rowIndex, isFalse:argValue, profileId: _user.profileId};
	  var _res = await AppHelper.apiPost(_url, _payload);
	  if (!_res || !_res.status) {
      alert('ERROR: Failed to set false!');
      return;
	  }
    
    _word.isFalse = argValue;
    setWords(_words);
    var _data = JSON.parse(_res.data);
    var _point = 0;
    if(_data.res){
      var _pointUC = _data.res.uk_uncommon_words?
        (_data.res.uk_uncommon_words.filter(r=>r.isFalse!=1).length*0.5) : 0;
      var _pointRA = _data.res.uk_rare_words?
        (_data.res.uk_rare_words.filter(r=>r.isFalse!=1).length*1) : 0;
      _point = _pointUC + _pointRA;
      console.log(_pointUC, _pointRA, _point);
    }
    let _vcxPoint = 1;
    if (_point >= 5) _vcxPoint = 10;
    else if (_point >= 4.5) _vcxPoint = 9;
    else if (_point >= 4) _vcxPoint = 8;
    else if (_point >= 3.5) _vcxPoint = 7;
    else if (_point >= 3) _vcxPoint = 6;
    else if (_point >= 2.5) _vcxPoint = 5;
    else if (_point >= 2) _vcxPoint = 4;
    else if (_point >= 1.5) _vcxPoint = 3;
    else if (_point >= 1) _vcxPoint = 2;

    if(onMarkDone) onMarkDone(_vcxPoint);
	};
  
  return(
    // <Dialog onClose={handleClose} open={open}>
    //   <DialogTitle>VOCABULARY (Uncommon/Rare Word)</DialogTitle>
      <Box sx={{  }}>        
      <Box padding={'0.5rem'} sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
        { scoreInfo && scoreInfo.rareWords && scoreInfo.rareWords.length>0 
        &&
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold'}}>Word</TableCell>
                <TableCell sx={{ fontWeight: 'bold'}}>Type</TableCell>
				<TableCell sx={{fontWeight:'bold'}}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { scoreInfo.rareWords.map((row, iLt)=>(
                <TableRow key={'vcx_row_' + iLt} style={{textDecoration:row.isFalse==1?'line-through':''}}>
                  <TableCell>{row.w}</TableCell>
                  <TableCell>
                    {row.t == 'U'? 
                      <Chip label="Uncommon" variant="filled" /> 
                      : <Chip label="Rare" variant="filled" style={{backgroundColor:'rgba(0,0,0,0.25)'}} />
                    }
                  </TableCell>
				          <TableCell>
                    { allowMark && row.isFalse != 1 && 
                    <Tooltip title="Mark as False Positive">
                      <IconButton onClick={() => onFalseClick(row.iWord, 1, row.t)} area-label="Mark as False Positive">
                        <RemoveCircleOutlineIcon style={{color:pink[500]}} />
                      </IconButton>
                    </Tooltip>
                    }
                    { allowMark && row.isFalse == 1 && 
                    <Tooltip title="Mark as Positive">
                      <IconButton onClick={() => onFalseClick(row.iWord, 0, row.t)} area-label="Re-mark as Positive">
                        <TaskAltIcon style={{color:green[500]}} />
                      </IconButton>
                    </Tooltip>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        }
      </Box>
	  </Box>
    // </Dialog>
  );
}