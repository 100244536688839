import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import {
  Avatar, Chip, Dialog, DialogTitle, FormLabel, FormControlLabel, Paper, Button, Divider,
  Radio, RadioGroup, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid, TableHead, TableBody, TableCell, TableContainer, TableRow, Table,
  TableSortLabel, Tooltip, List, ListItem, ListItemAvatar, ListItemText, ListItemButton, ListItemIcon,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { AppHelper } from '../AppHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { create } from "@mui/material/styles/createTransitions";
const dayjs = require("dayjs");

export default function PopUsageList({
  onClose, open, onSubmit, accountId
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const oUser = AppHelper.userCacheGet();
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal: 'center', msg: ''
  });
  const [usageData, setUsageData] = useState({
    text: '',
    files: [],
    usageList: '',
  });

  const [usageList, setUsageList] = useState([]);
  const [txtNameOK, setTxtNameOK] = useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [authOK, setAuthOK] = React.useState(false);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [filteredUsageLists, setFilteredUsageLists] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("latestTimestamp");
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (startDate && date && date < startDate) {
      setStartDate(date);
    } else if (!startDate) {
      setStartDate(date);
    }
  };


  const fetchFilteredUsageLists = async (searchText) => {
    try {
      setFilterText(searchText);
      const filteredList = usageList.filter(
        (x) =>
          x.remark.toLowerCase().includes(searchText.toLowerCase()) ||
          x.logDesc.toLowerCase().includes(searchText.toLowerCase())
      );
      const  aggregatedData = aggregateUsageData(filteredList);
      setFilteredUsageLists(aggregatedData);
    } catch (error) {
      console.error("Error fetching filtered usageLists:", error);
    }
  };

  const aggregateUsageData = (data) => {
    const activityList = [];
    let lastLogDesc = null;

    // data.sort((a, b) => dayjs(a.timestampUtc).isAfter(dayjs(b.timestampUtc)) ? 1 : -1);
    const sortedData = [...data].sort((a, b) => 
      dayjs(a.timestampUtc).isAfter(dayjs(b.timestampUtc)) ? 1 : -1
    );

    sortedData.forEach(item => {
        const key = item.logDesc;
        const formattedTimestamp = item.timestampUtc;

        if (lastLogDesc !== key) {
            if (activityList.length === 0 || activityList[activityList.length - 1].logDesc !== key) {
                activityList.push({
                    logDesc: key,
                    count: 1,
                    timestamps: [formattedTimestamp],
                    latestTimestamp: formattedTimestamp
                });
            } 
            // else {
            //     const lastEntry = activityList[activityList.length - 1];
            //     lastEntry.timestamps.push(formattedTimestamp);
            //     lastEntry.count++;
            //     lastEntry.latestTimestamp = formattedTimestamp;
            // }
            lastLogDesc = key;
        } else {
            // Continue the last entry
            const lastEntry = activityList[activityList.length - 1];
            lastEntry.timestamps.push(formattedTimestamp);
            lastEntry.count++;
            lastEntry.latestTimestamp = formattedTimestamp;  // Update the latest timestamp
        }
    });

    // setFilteredUsageLists(activityList);
    return activityList;
};

  const fetchUsageLists = async () => {
    var _res = await AppHelper.apiPost("api/audit/list", {
      token: oUser.token,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      accountId: accountId,
    });
    console.log("_res", _res);
    if (!_res || !_res.status) {
      setIsLoading(false);
      return;
    }

    _res.data.forEach((row) => {
      var _ts = dayjs(row.timestampUtc);
      row.tTitle = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` + _ts.format('DD MMM YYYY');
      row.logDesc = row.logCode == "RULE-UPDATE"
        ? "Update Scoring Rule"
		: row.logCode == "RULE-UPDATE-SA"
		? "Update Scoring Rule System"
        : row.logCode == "P-P"
          ? "View Profile Page"
          : row.logCode == "P-SA"
            ? "View SA Page"
            : row.logCode == "P-H"
              ? "View Home Page"
              : row.logCode == "P-ES" &&
                (!row.remark || row.remark == "" || row.remark == null)
                ? "View Essay Home Page"
                : row.logCode == "ES-ADD"
                  ? "Submit New Essay"
                  : row.logCode == "P-AI"
                    ? "Accept Invite"
                    : row.logCode == "P-QU"
                      ? "Question List"
                      : row.logCode == "P-CI"
                        ? "Reject Invite"
                        : row.logCode == "ES-DELETE"
                          ? "Delete Essay"
								: row.logCode == "P-SI" 
							? "Login"
							: row.logCode == "LOGIN" 
							? "Login"
							: row.logCode == "P-SO" 
							? "Logout"
							: row.logCode == "EX-ADD" 
							? "Submit New Exercise"
                            : "View Essay"
    });
    
    setUserName(_res.data[0].name);
    setUserEmail(_res.data[0].email);

    console.log('list', _res.data);
    const aggregatedData = aggregateUsageData(_res.data);
    setFilteredUsageLists(aggregatedData);
    setUsageList(_res.data);
  };

  useEffect(() => {

    var _user = AppHelper.userCacheGet();
    setAuthOK(_user != null);


    if (_user && open) {
      fetchUsageLists();
      setFilterText("");
    }
  }, [open, startDate, endDate]);



  const handleClose = () => { onClose() };

  function descendingComparator(a, b, orderBy) {  
    if (orderBy === "latestTimestamp") {
        return dayjs(a.timestampUtc).isAfter(dayjs(b.timestampUtc)) ? 1 : -1;
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const createSortHandler = (event, property) => {
  const isAsc = orderBy === property && order === "asc";
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth
        maxWidth={false}
        sx={{ '& .MuiDialog-paper': { width: '75%', maxWidth: 'none' } }}
      >
        <DialogTitle>{"USAGE LIST"}</DialogTitle>

        <List sx={{ml: '1rem'}}>
          <ListItem>
          <ListItemAvatar>
            <Avatar src={`data:image/png;base64, ${oUser.picture}`} />
          </ListItemAvatar>

          <ListItemText primary={userName}/>
          </ListItem>

          <ListItem disablePadding>
            <ListItemIcon>
              <MailOutlineIcon sx={{ml: '1.6rem', mr: '1.45rem'}}/>
            </ListItemIcon>
            <ListItemText primary={userEmail}/>
          </ListItem>
        </List>

        <Box pl={2} pr={2} pb={2} alignItems="flex-start">
          <Grid container>
            <Grid item xs={3} sm={3}>
              <Box m={0.5} pt={1}>
                <DatePicker
                  sx={{ width: "100%" }}
                  label="Start Date"
                  value={startDate}
                  format="DD MMM YYYY"
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={3} sm={3}>
              <Box m={0.5} pt={1}>
                <DatePicker
                  sx={{ width: "100%" }}
                  label="End Date"
                  value={endDate}
                  format="DD MMM YYYY"
                  onChange={handleEndDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box m={0.5} pt={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Filter Usage"
                  onChange={(e) => fetchFilteredUsageLists(e.target.value)}
                  value={filterText}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>


              <Box m={0.5}>
                <TableContainer style={{ maxHeight: 400, overflowY: 'auto' }}
                  className="scrollbar-001"
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'latestTimestamp'}
                            direction={orderBy === 'latestTimestamp' ? order : 'asc'}
                            onClick={(event) => createSortHandler(event, 'latestTimestamp')}
                          >
                          Timestamp
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'logDesc'}
                            direction={orderBy === 'logDesc' ? order : 'asc'}
                            onClick={(event) => createSortHandler(event, 'logDesc')}
                          >
                          Log Code
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {filteredUsageLists && filteredUsageLists.length > 0 &&
                      stableSort(filteredUsageLists, getComparator(order, orderBy)).map((row, index) => (
                          <TableRow key={row.logId}
                            sx={{
                              cursor: 'pointer',
                              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
                            }}>
                            <TableCell align="left">
                              {/* {new Date(row.tTitle).toLocaleString()} */}
                              {/* {row.tTitle} */}
                              {dayjs(row.latestTimestamp).format(`DD MMM 'YY hh:mm a`).toUpperCase()}
                            </TableCell>
                            <TableCell align="left">
                              {row.logDesc}
                            </TableCell>
                            <TableCell align="left">
                            {row.count > 1 && 
                              <Tooltip 
                                arrow
                                title={
                                  <div style={{whiteSpace: 'pre-line'}}>
                                    {row.timestamps.map(ts => dayjs(ts).format(`DD MMM 'YY hh:mm a`).toUpperCase()).join('\n')}
                                  </div>
                                }
                              >
                              <Chip 
                                label={row.count} 
                                style={{
                                  color: 'black', 
                                  minWidth: '20px', 
                                  height: '25px', 
                                  fontSize:'12px', 
                                  display: 'flex', 
                                  alignItems: 'center', 
                                  justifyContent: 'center',  
                                }} />
                              </Tooltip>
                            }
                            </TableCell>
                          </TableRow>
                      ))
                        // filteredUsageLists.map((row) => (
                          // <TableRow key={row.logId}
                          //   sx={{
                          //     cursor: 'pointer',
                          //     '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
                          //   }}>
                          //   <TableCell align="left">
                          //     {new Date(row.timestampUtc).toLocaleString()}
                          //   </TableCell>
                          //   <TableCell align="left">
                          //     {row.logDesc}
                          //   </TableCell>

                          // </TableRow>
                        // ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );

}
