import {
    Avatar, Button, Box, Card, CircularProgress, FormControl, Grid, IconButton, Input, InputLabel,
    Menu, MenuItem,
    Select, Stack, Switch, TextField, Typography, FormControlLabel, Checkbox,
    imageListClasses
  } from '@mui/material';
  
  
export default function ProcessStatus100(props){
    return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" value={100} color="success" />
        <Box
            sx={{
            top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
            display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
        >
        <Typography variant="caption" component="div" color="text.info">{`100%`}</Typography>
        </Box>
    </Box>
    );
}