import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery } from '@mui/material';
import { AppHelper } from '../../AppHelper';
import {
  Button, Box, Card, Dialog, DialogTitle, Grid, IconButton,
  Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { pink, green } from '@mui/material/colors';
import { diffWordsWithSpace } from 'diff';

export default function PopGMErrors ({
  onClose, open, scoreInfo, essay, allowMark, onMarkDone
}){
  const [gmErrors, setGmErrors] = useState([]);
  useEffect(()=>{
    if(!scoreInfo || !scoreInfo.errors) return;
    setGmErrors(scoreInfo.errors.filter(x=>x.isFalse!=1 || allowMark));
  }, [scoreInfo, allowMark]);
  const getDiffs = (str1, str2) => {
    const differences = diffWordsWithSpace(str1, str2);
    return differences.map((part, index) => {
      const style = {
        color: part.added ? '#1976d2' : part.removed ? '#ef5350' : '#000',
        textDecoration: part.added? 'underline' : part.removed ? 'line-through' : 'none',
        fontWeight: (part.added || part.removed) ? 'bold' : 'normal',
      };
      return <span key={index} style={ style }>{part.value}</span>;
    });
  }
  const handleClose = () =>{ onClose() };
  const copySentence = (arg)=>{
    navigator.clipboard.writeText(arg);
  };
  
  const onFalseClick = async(rowIndex, argValue) => {
	  var _user = AppHelper.userCacheGet();
	  var _url = 'api/score/GM/error/false'; 
	  var _payload = {token: _user.token, essayId: essay.essayId, errorIndex: rowIndex, isFalse:argValue, profileId: _user.profileId };
	  
	  var _res = await AppHelper.apiPost(_url, _payload);
	  if (!_res || !_res.status) {
      alert('ERROR: Failed to set false!');
      return;
	  }
	  //alert("Success");
    var _err = gmErrors[rowIndex];
    _err.isFalse = argValue;
    var _errorCount = gmErrors.filter(x=>x.isFalse!=1).length;
    if(onMarkDone) onMarkDone(_errorCount);
	};
	
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  
  return(
    // <Dialog onClose={handleClose} open={open}>
    //   <DialogTitle>GRAMMAR MISTAKES</DialogTitle>
	<Box sx={{ width: "100%", overflowX: "auto" }}>  {/* Allow horizontal scroll */}
	   <Box padding={'0.5rem'} 
	    sx={{
			...(isSmallScreen 
			  ? { width: "100%", tableLayout: "fixed", display:"table" }  // Apply on small screens
			  : {  }                        // Default for larger screens
			)
		  }}	 
        >		  
		{gmErrors && gmErrors.length > 0 && (
		  <TableContainer component={Paper}>
			<Table 
			  aria-label="simple table" 
			  sx={{
				...(isSmallScreen 
				  ? { width: "", tableLayout: "fixed", overflowX:"hidden" }  // Apply on small screens
				  : { width: "auto" }                        // Default for larger screens
				)
			  }}
			>
			  <TableHead>
				<TableRow>
					<TableCell sx={{ fontWeight: 'bold', ...(isSmallScreen && { width: 220 }) }}>Wrong Sentence</TableCell>
					<TableCell sx={{ fontWeight: 'bold', ...(isSmallScreen && { width: 220 }) }}>Correct Sentence</TableCell>
					<TableCell sx={{ fontWeight: 'bold', ...(isSmallScreen && { width: 120 }) }}>Type</TableCell>
					<TableCell sx={{ fontWeight: 'bold', ...(isSmallScreen && { width: 120 }) }}>Subtype</TableCell>
					<TableCell sx={{ fontWeight: 'bold', ...(isSmallScreen && { width: 100 }) }}></TableCell>
				</TableRow>
			  </TableHead>
			  <TableBody>
				{gmErrors.map((row, iRow) => (
				  <TableRow key={`tr_${iRow}_E`} style={{ textDecoration: row.isFalse == 1 ? 'line-through' : '' }}>
					<TableCell>{row.w}</TableCell>
					<TableCell>
					  {getDiffs(row.w, row.r)}
					  <ContentCopyIcon
						onClick={() => copySentence(row.r)}
						sx={{ marginLeft: '0.5rem', cursor: 'pointer', opacity: '0.35', fontSize: '1rem' }}
						title={'click to copy'}
					  />
					</TableCell>
					<TableCell>{row.type}</TableCell>
					<TableCell>{row.subType}</TableCell>
					<TableCell>
					  {allowMark && row.isFalse != 1 && (
						<Tooltip title="Mark as False Positive">
						  <IconButton onClick={() => onFalseClick(iRow, 1)} area-label="Mark as False Positive">
							<RemoveCircleOutlineIcon style={{ color: pink[500] }} />
						  </IconButton>
						</Tooltip>
					  )}
					  {allowMark && row.isFalse == 1 && (
						<Tooltip title="Mark as Positive">
						  <IconButton onClick={() => onFalseClick(iRow, 0)} area-label="Re-mark as Positive">
							<TaskAltIcon style={{ color: green[500] }} />
						  </IconButton>
						</Tooltip>
					  )}
					</TableCell>
				  </TableRow>
				))}
			  </TableBody>
			</Table>
		  </TableContainer>
		)}
	  </Box>
	</Box>

    // </Dialog>
  );
}