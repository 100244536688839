import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, CircularProgress, Divider, FormControl, InputBase, IconButton, InputLabel, List, 
  ListItem, ListItemButton, OutlinedInput, Paper, TextField, Typography}
from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useExerciseContext } from "../contexts/context-exercise";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import InputAdornment from '@mui/material/InputAdornment';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} 
          sx={{
            backgroundColor:'#b9f6ca',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#00c853'
            }
          }} 
        />
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary" fontSize={"0.7rem"}
          sx={{
            color: props.recSelected==1?'rgba(0,0,0,0.9)':'text.secondary'
          }}>
          {`${Math.round(props.value)}%`}
          </Typography>
      </Box>
    </Box>
  );
}

function HomeDrawerExercise({isSa, essayAddClick, onSelectItem, viewMode}) {
  const {recList, isListLoaded, loadList, recSelect, isListLoading} = useExerciseContext();
  //const [visibleEssays, setVisibleEssays] = useState([]);
  const [filterExercise, setFilterExercise] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const chipSampleColors = {
    '1': 'rgba(19,130,117,1)',
    '0': 'white'
  };
  const drawerItemColrs = {
    '1x': 'rgba(0,0,0,0.25)',
    '1xx': 'rgb(157, 188, 152)',
    '1xxx': 'rgb(99, 136, 137)',
    '1': '#d3d3d3',//'rgba(19,130,117,1)',
    '0x': 'white',
    '0': '#fff'//'rgba(255,255,255,1)',//'rgb(235, 217, 180)',
  };
  const drawerItemColorsText = {
    '1': 'rgba(255,255,255,1)',
    '0': 'rgba(0,0,0,0.6)',
  };
  
  useEffect(()=>{
    if(isListLoading != true && isListLoading != false) return;
    setIsLoading(isListLoading);
  }, [isListLoading]);
  const listFilterExercise = ()=>{
    var _list = [...recList];
    // _list.forEach((r, iR)=>{
    //   r.viewTitle = `Exercise ${iR+1}`;
    //   var _qCount = 0;
    //   r.pages.map(p=> _qCount += p.questions.length);
    //   r.grade = `3/${_qCount}`;// + 
    // });
    // _list = [{viewTitle: '1', grade: '3/4'}];
    if(filterExercise && filterExercise.length>0){
      var _filterText = filterExercise.toLowerCase();
      _list = _list.filter(r=>
        r.grade.toLowerCase().includes(_filterText)
        || 
        r.viewTitle.toLowerCase().includes(_filterText)
        ||
          r.questionSetName.toLowerCase().includes(_filterText)
        // || r.email.toLowerCase().includes(_filterText)
        // || r.versionTitle.toLowerCase().includes(_filterText)
        // || r.tTitle.toLowerCase().includes(_filterText)
        // || r.avgScoreP.toString().toLowerCase().includes(_filterText)
      );
    }
    return _list;//stableSort(_list, getComparator(tblOrderEssay, tblOrderByEssay));
  };
  const visibleEssays = React.useMemo(
    () =>
      listFilterExercise()
      ,
      [recList, filterExercise]
  );
  const filterExerciseChange = (event)=>{
    setFilterExercise(event.target.value);
  };
  const onSelect = (arg) => {
    if(onSelectItem) onSelectItem(arg);
  };
  const onAddClick = ()=>{
    console.log('onAddClick');
    if(essayAddClick) essayAddClick();
  };

  return (
    <>
    <Box sx={{ 
      //backgroundColor:'#fff',
      overflowY: "auto", 
      '&::-webkit-scrollbar': {
        width: '6px',      // Width of the scrollbar
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1', // Track color
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',     // Thumb color
        borderRadius: '4px',   // Border radius
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',     // Thumb color on hover
      },
      marginBottom: viewMode === "VIEW" ? '115px' : '60px'
    }}>
      <Box width={'100%'}>
        {/* Old Version Search Bar */}
        {/* <Box sx={{padding:'5px 5px 0 5px'}}>
          <TextField value={filterExercise} onChange={filterExerciseChange} id="txtFilterExercise" label="" variant="standard" placeholder="Search.." fullWidth />
        </Box> */}
        <Paper
          component="form"
          sx={{ margin: '5px 5px 0 5px', 
                padding:'5px 5px 0 5px', 
                display: 'flex', 
                alignItems: 'center'}}
        >
        <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'Search' }}
            onChange={filterExerciseChange}
        />
        <IconButton sx={{ p: '10px' }} aria-label="search">
          <SearchRoundedIcon />
        </IconButton>
        </Paper>

        { isLoading &&
          <div style={{margin:'1rem'}}>
            <CircularProgress />
          </div>
        }
        { !isLoading &&
        <List sx={{ fontFamily:'Noto Sans'}}>
          {visibleEssays.map((rec, index) => (
            <React.Fragment key={rec.exerciseId}>
              <ListItem 
                sx={{ 
                  backgroundColor: rec.selected? drawerItemColrs[rec.selected] : drawerItemColrs['0'],
                  color: rec.selected? drawerItemColorsText[rec.selected] : drawerItemColorsText['0'],
                  borderBottom:'1px solid #999'
                }} 
                disablePadding>
                <ListItemButton onClick={() => onSelect(rec)}>
                  <div style={{widht:'100%', flex:1}}>
                    <Box display="flex" alignItems="center">                     
					  <Typography sx={{ fontWeight: 'bold', color: rec.selected === '1' ? 'rgba(0,0,0,0.9)' : 'rgba(0,0,0,0.6)' }}>
					  {rec.viewTitle}
					  {rec.questionSetName && (
						<>
						  <br />
						  {rec.questionSetName}, {rec.grade}
						</>
					  )}
					</Typography>
                      { rec.isSample == 1 && 
                        <Box marginLeft="auto">
                          {/* <BookmarksIcon />  */}
                          <Chip label={'Sample'} variant="filled" style={{
                            backgroundColor:chipSampleColors[rec.selected=='1'?'0':'1'],
                            color:chipSampleColors[rec.selected=='1'?'1':'0']
                          }} 
                          sx={{fontSize:'1.0rem', marginTop:'0rem', padding:'0.1px'}}
                          />
                        </Box>
                      }
                      {rec.isSample != 1 && 
                        <Box marginLeft="auto">
                        <Chip 
						  label={rec.scoreTitle}
						  variant="filled" 
						  style={{
							backgroundColor: chipSampleColors[rec.selected == '1' ? '0' : '1'],
							color: chipSampleColors[rec.selected == '1' ? '1' : '0']
						  }} 
						  sx={{
							fontSize: '1rem',  // Reduce font size for a smaller chip
							height: '25px',      // Set a smaller fixed height
							minHeight: '25px',   // Ensure it stays compact
							lineHeight: '25px',  // Align text properly inside the chip
							padding: '0px 0px',  // Minimize padding for compactness
							marginTop: '0rem'
						  }} 
						/>

                      </Box>
                      }
                    </Box>
                    <div>
                    {/* {rec.status > 0 ? (
                      <>
                        {rec.status == 5?
                          <LinearProgressWithLabel value={rec.avgScoreP}  />
                          :
                          <>
                          { rec.isInProgress &&
                            <CircularProgress style={{width:'20px', height:'20px'}} />
                          }
                          </>
                        }
                      </>
                    ) : (
                      <Typography variant="body2" color="textSecondary">Draft</Typography>
                    )} */}
                    </div>
                    <Typography
                      sx={{ display: 'inline',
                        color: rec.selected=='1'?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.6)',
                      }}
                      component="span" variant="body2"
                    >
                      {rec.tTitle}
                      {/* { isSa &&
                      <div>
                        <Typography sx={{ color:'blue', display:'inline', opacity:'0.7', fontSize:'0.6rem' }}>
                          {rec.versionTitle} | v{rec.vCorr=='2.00'?'2.0':rec.vCorr=='1.00'?'1.0':rec.vCorr}
                        </Typography>
                      </div>
                      } */}
                    </Typography>
                  </div>
                </ListItemButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        }
      </Box>
    </Box>
    <div
      style={{
        position: "absolute",
        bottom: 50,
        right: 0,
        left: 0,
        //backgroundColor: "white",
        height:'60px',
        borderTop: '0 solid rgba(0,0,0,0.12)',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
      }}
    >
      { viewMode === "VIEW" &&
        <Button className="app-btn-bg" sx={{
          width:'100%',
          margin:'0 5px 22px 5px'
          }}
          onClick={onAddClick} color="info" variant="contained" size="large">
          + ADD
        </Button>
      // </Box>
    }
    </div>
    </>
  );
}

export default HomeDrawerExercise;
