import React, { useEffect, useState } from "react";
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import '../../App.css';
import './sentence-transform-exercise.css';
import { AppHelper } from "../../AppHelper";
import SentenceTransformQnA from "./sentence-transform-qna";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { diffWordsWithSpace } from 'diff';

export default function SentenceTransformExerciseView({question}) {
	const [records, setRecords] = useState([]);

  useEffect(() => {
		if(!question) return;
		setRecords(question.generatedExercises);
		console.log('exec-view',question);
  }, [question]);
	function parseAndUnderline(text) {
		const parts = text.split(/<\/?u>/);
		return parts.map((part, index) => {
			if (index % 2 === 1) {
				// return <span key={index} style={{textDecoration:'underline'}}>{part}</span>;
				return <span key={index} className="custom-underline">{part}</span>;
			}else{
				return <span key={index}>{part}</span>;
			}
		});
	}
	function answerTextRedOrBlue(str1, str2) {
		const answers = [];
		const regexA = /<a>(.*?)<\/a>/g;
		let match;
		while ((match = regexA.exec(str2)) !== null) {
		  answers.push(match[1]);
		}
		let answerIndex = 0;
		const replacedStr = str1.replace(/<u>.*?<\/u>/g, () => {
		  const replacement = answers[answerIndex] || "";
		  answerIndex++;
		  return replacement;
		});
	  
		const str1_clean = str1.replaceAll('<u>', '').replaceAll('</u>', '');
		const _diff = getDifferences(str1_clean, replacedStr);
		return _diff;
	  }

			
		function normaliseCorrectAnswer(input) {
			const trimmed = input.trim();
			if (!/[.!?]$/.test(trimmed)){
				return `${trimmed}.`;
			}
			return trimmed;
		};

		const getDifferences = (line1, line2) => {
		// const getDifferences = (line1, line2) => {
		line2 = normaliseCorrectAnswer(line2);
			if(!line1 || !line2) return <span></span>;
			const differences = diffWordsWithSpace(line1, line2);
			return differences.map((part, index) => {
				const style = {
					color: part.added ? '#1976d2' : part.removed ? '#ef5350' : '#000',
					textDecoration: part.added? 'underline' : part.removed ? 'line-through' : 'none',
					fontWeight: (part.added || part.removed) ? 'bold' : 'normal',
				};
				const textContent = (part.added || part.removed) ? ` ${part.value}` : part.value;
				return <span key={index} style={ style }>{convertNewlinesToBr(textContent)}</span>;
				// return <span key={index} style={ style }>{convertNewlinesToBr(part.value)}</span>;
			});
		};
		const convertNewlinesToBr = (text) => {
			if(!text) return <React.Fragment></React.Fragment>;
			text = text.replace(/<[^>]*>/g, '');
			return text.split('\n').map((line, index) => (
				<React.Fragment key={index}>
					{line}
					{index < text.split('\n').length - 1 && <br />}
				</React.Fragment>
			));
		};
	return(
		<>
			{question && 
			<div style={{marginLeft:'.5rem', borderTop:'1px solid rgba(0,0,0,.12)', paddingTop:'.3rem'}}>
				<div id="boxExercise">
					<div style={{fontSize:'1.3rem', fontWeight:'bold', marginBottom:'.7rem', color:'rgba(19,130,117,1)'}}>EXERCISE ({question.exerciseY}/{records.length})</div>
					{records.map((rec,iRec)=>(
						<div key={'exercise_new_' + iRec} style={{fontWeight:'normal', marginBottom:'.5rem'}}>
							<Box display={'flex'} alignItems={'center'} justifyItems={'center'} marginBottom={'.2rem'}>
								{/* <HelpIcon sx={{ color:'#424242', marginRight:'.2rem', opacity:.7}} /> */}
								<Box className='qna-circle'>
									<Typography sx={{ 
										color:'rgba(0,0,0,.6)', fontSize:'1.5rem', fontWeight:'bold',
										minWidth:'1.8rem', textAlign:'center', }}>Q</Typography>
								</Box>
								{/* <Typography color={'rgba(0,0,0,1)'}></Typography> */}
								<span style={{fontWeight:'bold'}}>{rec.originalSentence}</span>
							</Box>
							<div>
								{ rec.status == 1 &&
								<div style={{alignItems: 'center'}}>
									<Box display={'flex'} alignItems={'center'} justifyItems={'center'} marginBottom={'.2rem'}>
										{/* <HelpIcon sx={{ color:'#424242', marginRight:'.2rem', opacity:.7}} /> */}
										<Box className='qna-circle'>
											<CheckIcon sx={{marginRight:'.2rem', marginLeft:'.2rem', color:'rgba(19,130,117,1)'}} />
										</Box>
										{/* <Typography color={'rgba(0,0,0,1)'}></Typography> */}
										<span style={{fontWeight:'bold'}}>{parseAndUnderline(rec.question)}</span>
									</Box>
								</div>}
								
								{/* { rec.status == 3 &&
								// <span style={{color:'red', fontWeight:'bold'}}>A</span>
								// rec.userInput.map((input, index) => (
								// 	<span key={index} style={{color: 'blue' }}>{input}</span>
								// ))}
								answerTextRedOrBlue(rec.correctedAnswer, rec.originalSentence)
								// <SentenceTransformQnA key={'qna_3_' + iRec} 
								// 	status={rec.status}
								// 	questionText={rec.originalSentence} 
								// 	answer={rec.question} 
								// 	correctedAnswer={rec.correctedAnswer}>
								// </SentenceTransformQnA>
								} */}
								{/* : 
								{ rec.status != 2 &&
									<>&nbsp;{ parseAndUnderline(rec.correctedAnswer)}</>
								} */}
								{ rec.status == 3 && 
								<div style={{display: 'flex', alignItems: 'center'}}>
								<Box display={'flex'} alignItems={'center'} justifyItems={'center'} marginBottom={'.2rem'}>
										{/* <HelpIcon sx={{ color:'#424242', marginRight:'.2rem', opacity:.7}} /> */}
										<Box className='qna-circle'>
											<CloseIcon sx={{marginRight:'.2rem', marginLeft:'.2rem', color:'red'}} />
										</Box>
										{/* <div dangerouslySetInnerHTML={{ __html: answerTextRedOrBlue(rec.question, rec.correctedAnswer) }}></div> */}
										<Box>{answerTextRedOrBlue(rec.question, rec.correctedAnswer)}</Box>
									</Box>
								</div>
								}
								<br></br>
							</div>
						</div>
					))}
				</div>
			</div>
			}
		</>
	)
}