import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import './sentence-transform-q-update.css';
import { useNavigate } from 'react-router-dom';
import { AppHelper } from '../../AppHelper';
import {
  Dialog, DialogTitle, FormLabel, FormControlLabel, Paper, Button, Divider,
  Radio, RadioGroup, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid
} from '@mui/material';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEditor, EditorContent } from '@tiptap/react';
import { EditorProvider, useCurrentEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit';
import Code from '@tiptap/extension-code';

export default function PopSentenceTransformUpdateQuestion({
  onClose, open, model
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSa, setIsSa] = useState(false);
  const [oUser, setOUser] = React.useState(null);
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal: 'center', msg: ''
  });
  const [questionData, setQuestionData] = useState({
    originalSentence: '',
    questionId: '',
    question: '',
    questionNo: ''
  });

  const [txtNameOK, setTxtNameOK] = useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [title, setTitle] = useState('');
  const [topic, setTopic] = useState('');
  const [authOK, setAuthOK] = React.useState(false);
  const [question, setQuestion] = useState('');
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [transcribe, setTranscribe] = useState('');

  const editor = useEditor({
    extensions: [StarterKit, Code],
    content: questionData.question,
    editorProps: {
      attributes: {
        style: `
		margin-top: 0px;
		margin-left: -10px;
		margin-right: -10px;
        border: 1px solid grey; 
        border-radius: 5px; 
		min-height:80px;
        padding-left: 10px;
		padding-right: 10px;
    line-height: 1.9;
      `,
      },
    },
    onSelectionUpdate: ({ editor }) => {
      const { from, to } = editor.state.selection;

      // Check if text is selected
      if (from !== to) {
        editor.commands.toggleCode();
      }
    },
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();

      setQuestionData({ ...questionData, question: html });
    },
  });

  const fetchIAM = async (oUser) => {
    var _res = await AppHelper.apiPost('api/account/iam', { token: oUser.token });
    if (!_res || !_res.status || !_res.data || !_res.data.isSa) {
      return;
    }
    setIsSa(true);
  }


  useEffect(() => {
    var _user = AppHelper.userCacheGet();
    setAuthOK(_user != null);
    setOUser(_user);
    if (_user && _user.grade) {
      fetchIAM(_user);
    }
  }, []);

  useEffect(() => {
    if (model) {
      setIsEdit(true);
      setQuestionData({
        originalSentence: model.originalSentence,
        questionId: model.questionId,
        question: model.question,
      });
      editor.commands.setContent(model.question.replace(/<u>/g, '<code>').replace(/<\/u>/g, '</code>'));
    }
  }, [model]);

  const handleSubmit = async () => {
    setLoading(true);
    await handleQuestionSubmit();
    setLoading(false);
  };

  const handleQuestionSubmit = async () => {
    if (AppHelper.isEmptyString(questionData.question)) {
      alert('Question is required');
      return;
    }

    if (AppHelper.isEmptyString(questionData.originalSentence)) {
      alert('Corrected Answer is required');
      return;
    }

    const cleanedQuestion = questionData.question.replace(/<\/?p>/g, '').replace(/<code>/g, '<u>').replace(/<\/code>/g, '</u>');

    const _url = 'api/exercise/sentenceTransform/question/update';
    var _user = AppHelper.userCacheGet();
    const _res = await AppHelper.apiPost(_url, {
      token: _user.token,
      question: {
        questionId: model.questionId,
        originalSentence: questionData.originalSentence,
        // question: questionData.question.replace(/<code>/g, '<u>').replace(/<\/code>/g, '</u>'),
        question: cleanedQuestion,
      }
    });

    if (!_res || !_res.status) {
      alert('ERROR: Failed to update question!');
      return;
    }
    else {
      setQuestionData({
        originalSentence: '',
        questionId: '',
        question: '',
      });
      alert('Question updated!');
      onClose();
    }
  };

  const handleClose = () => { onClose() };

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogTitle>{model ? "EDIT QUESTION" : "ADD QUESTION"}</DialogTitle>
        <Box pl={2} pr={2} pb={2} alignItems="flex-start">

          <Grid>
            <Grid item xs={12} sm={6}>
              <Box m={.5} >


                <Grid item mb={0.5}>
                  <TextField
                    label="Question"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    value={questionData.originalSentence}
                    onChange={(e) => setQuestionData({ ...questionData, originalSentence: e.target.value })}
                  />
                </Grid>

                <Grid item style={{ marginTop: '0' }}>
                  {/* <div style={{ border: '1px solid black', padding: '10px', borderRadius: '5px' }}> */}

                    <EditorContent editor={editor} style={{ padding: '10px', borderRadius: '5px' }}/>
                  {/* </div> */}
                </Grid>



              </Box>
            </Grid>
            <Grid container pt={.5}>
              <Grid item xs={6}>

              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">

                  <LoadingButton
                    onClick={handleSubmit}
                    // endIcon={<SendIcon />}
                    loading={isSubmitting}
                    //loadingPosition="start"
                    variant="contained"
                  >
                    <span>SUBMIT</span>
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>

      </Dialog>
    </>
  );
}
