import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import PopQuestion from './pop-question';
import '../../App.css';
import AppBar from '@mui/material/AppBar';
import { EssayHelper } from "../../helpers/essay.helper";
import ScanPopup from './scan-popup';
import ScanCam from './scan-cam';
import {
  Avatar, Button, Box, Card, CircularProgress, FormControl, Grid, IconButton, Input, InputLabel,
  Menu, MenuItem,
  Select, Stack, Switch, TextField, Typography, FormControlLabel, Checkbox,
  imageListClasses
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { auth } from '../../firebase';
import { useUser } from '../../UserContext';
import { AppHelper } from '../../AppHelper';
import './essay-form.css';
import PopScanAgain from "./pop-scan-again";
import EssayViewQuestion from './essay-view-question';
import ProcessStatus100 from './process-status-100';
//import Typewriter from 'typewriter-effect/dist/core';
const configJson = require('../../config.json');
const dayjs = require('dayjs');

export default function EssaySubmitStatus(props) {
    const { essayId, essayFormDone, essayStatusCheckDone } = props;
    const [vMode, setVMode] = React.useState('PROCESSING');
    const [oUser, setOUser] = React.useState(null);
    const [authOK, setAuthOK] = React.useState(false);
    const [grade, setGrade] = useState('P5');
    const txtName = useRef(null);
    const [evalStatusSP, setEvalStatusSP] = React.useState({ done: false, v: 50 });
    const [evalStatusCT, setEvalStatusCT] = React.useState({ done: false, v: 50 });
    const [evalStatusCR, setEvalStatusCR] = React.useState({ done: false, v: 50 });
    const [evalStatusGM, setEvalStatusGM] = React.useState({ done: false, v: 50 });
    const [evalStatusVC, setEvalStatusVC] = React.useState({ done: false, v: 50 });
    const [evalStatusST, setEvalStatusST] = React.useState({ done: false, v: 50 });
    const [doneEval, setDoneEval] = React.useState(false);
    const [doneCorrect, setDoneCorrect] = React.useState(false);
   
    useEffect(() => {
        var _user = AppHelper.userCacheGet();
        setAuthOK(_user != null);
        setOUser(_user);
        if (_user && _user.grade) {
          setGrade(_user.grade);
        }
    }, [essayId]);

    useEffect(() => {
        if (!oUser || !essayId) {
            return;
        }
        checkEssayStatus(essayId);
    }, [essayId, oUser]);

    const onNewEssay = () => {
        console.log('onNewEssay');
        window.location.reload();
    }
    const onRefresh = () => {
        essayFormDone({ code: 'NEW', essayId: essayId });
    }
   
    const checkEssayStatus = async (argEssayId) => {
        var _res = await AppHelper.apiPost('api/essay/detail', { token: oUser.token, essayId: argEssayId });
        if (!_res || !_res.status) {
          alert('ERROR: Failed to load!');
          return;
        }
        checkEssayEvalStatus(_res.data.remark);
        if (_res.data.status == 8) {  //-- ERROR in EVAL or CORR
          console.log('error: ');
          alert('Submission failed. Please try again!');
          setVMode('NEW');
          setTimeout(() => {
            essayFormDone({ code:'ERROR', essayId: argEssayId });
          }, 1500);
          return;
        }
        if (_res.data.status != 5) {
          if (_res.data.status == 3)
            setDoneEval(true);
          if (_res.data.status == 4)
            setDoneCorrect(true);
   
          setTimeout(() => {
            checkEssayStatus(argEssayId);
          }, 3000);
          return;
        }
        
      setTimeout(() => {
        if (essayStatusCheckDone) {
          essayStatusCheckDone({ code: 'NEW', essayId: argEssayId });
        } else if (essayFormDone) {
          essayFormDone({ code: 'NEW', essayId: argEssayId });
        }
      }, 3000);
    }

    const checkEssayEvalStatus = (argStatus) => {
        console.log('essay status', argStatus);
        if (!argStatus || argStatus.length < 6) return;
        if (argStatus.charAt(1) == '1') setEvalStatusCT({ done: true, v: 100 }); //1
        if (argStatus.charAt(2) == '1') setEvalStatusCR({ done: true, v: 100 }); //2
        if (argStatus.charAt(6) == '1') setEvalStatusSP({ done: true, v: 100 }); //6
        if (argStatus.charAt(5) == '1') setEvalStatusGM({ done: true, v: 100 }); //5
        if (argStatus.charAt(4) == '1') setEvalStatusVC({ done: true, v: 100 }); //4
        if (argStatus.charAt(3) == '1') setEvalStatusST({ done: true, v: 100 }); //3
        if (argStatus.charAt(0) == '1') setDoneCorrect(true);
      // Backup purpose: Previous order
    //     if (argStatus.charAt(6) == '1') setEvalStatusCT({ done: true, v: 100 });
    //     if (argStatus.charAt(5) == '1') setEvalStatusCR({ done: true, v: 100 });
    //     if (argStatus.charAt(4) == '1') setEvalStatusSP({ done: true, v: 100 });
    //     if (argStatus.charAt(3) == '1') setEvalStatusGM({ done: true, v: 100 });
    //     if (argStatus.charAt(2) == '1') setEvalStatusVC({ done: true, v: 100 });
    //     if (argStatus.charAt(1) == '1') setEvalStatusST({ done: true, v: 100 });
    //     if (argStatus.charAt(0) == '1') setDoneCorrect(true);
    }

    return (
        <>
    <Grid container mt={1} mb={1} ml={1} mr={1}>
      <Grid item xs={12} mt={1}>
        <Box style={{ textAlign: 'center' }} mt={1}>
          {/* We are working on your submission. */}
          We have received your submission. It'll take around 2 minutes to process.
        </Box>
        <Box style={{ textAlign: 'center' }} mt={3}>
          <Button className="app-btn-bg" onClick={onNewEssay} color="info" variant="contained">Try a New Essay</Button>
        </Box>
      </Grid>
      <Grid item xs={12} mt={1}>
        Processing..
        <LinearProgress color="info" sx={{ marginTop: '1rem' }} />
      </Grid>
      {/* <Grid item xs={12} mt={5}>
      <Box display={'flex'} alignItems={'center'}>
      { !doneEval &&
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress />
          <Box
            sx={{
              top: 0, left: 0, bottom: 0, right: 0,
              position: 'absolute',
              display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.info">
              {`${evalStatusP}%`}
            </Typography>
          </Box>
        </Box>
      }
        { doneEval &&
          (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress variant="determinate" value={100} color="success" />
              <Box
                sx={{
                  top: 0, left: 0, bottom: 0, right: 0,
                  position: 'absolute',
                  display: 'flex', alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Typography variant="caption" component="div" color="text.info">
                  {`100%`}
                </Typography>
              </Box>
            </Box>
          )
        }
        <Typography sx={{marginLeft:'10px'}}>Evaluation ({evalStatus})</Typography>
      </Box>
    </Grid> */}
      <Grid item xs={12} mt={2}>
        <Box display={'flex'} alignItems={'center'}>
        { 
          evalStatusCT.done ?
          <ProcessStatus100></ProcessStatus100>
          : <CircularProgress />
        }
          <Typography sx={{ marginLeft: '10px' }}>Development</Typography>
        </Box>
      </Grid>
      
      
      <Grid item xs={12} mt={2}>
        <Box display={'flex'} alignItems={'center'}>
          { 
            evalStatusCR.done ?
            <ProcessStatus100></ProcessStatus100>
            : <CircularProgress />
          }
          <Typography sx={{ marginLeft: '10px' }}>Relevance &amp; Logic</Typography>
        </Box>
      </Grid>
    
      <Grid item xs={12} mt={2}>
        <Box display={'flex'} alignItems={'center'}>
          { 
            evalStatusGM.done ?
            <ProcessStatus100></ProcessStatus100>
            : <CircularProgress />
          }
          <Typography sx={{ marginLeft: '10px' }}>Grammar</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Box display={'flex'} alignItems={'center'}>
          { 
            evalStatusSP.done ?
            <ProcessStatus100></ProcessStatus100>
            : <CircularProgress />
          }
          <Typography sx={{ marginLeft: '10px' }}>Spelling</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Box display={'flex'} alignItems={'center'}>
          { 
            evalStatusVC.done ?
            <ProcessStatus100></ProcessStatus100>
            : <CircularProgress />
          }
          <Typography sx={{ marginLeft: '10px' }}>Vocabulary</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Box display={'flex'} alignItems={'center'}>
          { 
            evalStatusST.done ?
            <ProcessStatus100></ProcessStatus100>
            : <CircularProgress />
          }
          <Typography sx={{ marginLeft: '10px' }}>Structure & Flow</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Box display={'flex'} alignItems={'center'}>
          { 
            doneCorrect ?
            <ProcessStatus100></ProcessStatus100>
            : <CircularProgress />
          }
          <Typography sx={{ marginLeft: '10px' }}>Correction</Typography>
        </Box>
      </Grid>
      {essayId && essayId.length > 0 &&
        <>
          <Grid item xs={12} mt={3}>
            Taking too long?
          </Grid>
          <Grid item xs={12} mt={1}>
            <Button className="app-btn-bg" 
            onClick={onRefresh} 
            color="info" variant="contained">Refresh</Button>
          </Grid>
        </>
      }
    </Grid>
    </>
    );
}